function isAndroidWebView() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Detect Android WebView
  if (/Android/.test(userAgent)) {
    // Differentiate WebView from Chrome for Android
    return !(
      'requestFileSystem' in window || 'webkitRequestFileSystem' in window
    )
  }

  return false
}
function isIOSWebView() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Detect WebView (iOS)
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    // Differentiation based on the presence of specific APIs
    // WebView lacks standalone mode and some specific APIs
    return (
      !window.indexedDB ||
      !(window.localStorage && window.sessionStorage) ||
      !(
        document.createElement('a').relList &&
        document.createElement('a').relList.supports('noopener')
      )
    )
  }

  return false
}

export default function isRunningInWebView() {
  return isAndroidWebView() || isIOSWebView()
}

export const toLocalISOString = (date) => {
  const tzOffset = date.getTimezoneOffset() * 60000
  const localISOTime = new Date(date - tzOffset).toISOString().slice(0, -1)
  return localISOTime
}
