import React from 'react'
import { Button } from '@heroui/react'
import { withTranslation } from 'next-i18next'
import { useTheme } from 'next-themes'
import { ToggleThemeIcon } from './navIcons'

function ToggleThemeButton() {
  const { theme, setTheme } = useTheme()

  const toggleTheme = () => {
    if (theme == 'dark') {
      setTheme('light')
    } else {
      setTheme('dark')
    }
  }

  return (
    <Button
      radius="full"
      isIconOnly
      variant="faded"
      className={'menu-icon-button'}
      aria-label="theme-toggle-button"
      onClick={toggleTheme}
      size="sm"
    >
      <ToggleThemeIcon />
    </Button>
  )
}

export default withTranslation()(ToggleThemeButton)
