import { useMemo } from 'react'
import { cn } from '@heroui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { useCurrencyStore } from '../store/useCurrencyStore'
import { formatNumber } from '@gg/shared/utils/helpers'

const CurrencyField = ({
  amount,
  className,
  hideTilde = false,
  unStyled = false,
  withBraces = false,
}: {
  amount: number
  className?: string
  hideTilde?: boolean
  unStyled?: boolean
  withBraces?: boolean
}) => {
  const currency = useCurrencyStore((state) => state.currency)
  const latestRate = useCurrencyStore((state) => state.latestRate)
  const digit = useCurrencyStore((state) => state.digit)

  const converted = useMemo(() => {
    if (latestRate > 0 && amount > 0)
      return formatNumber(Math.round(amount * latestRate * 10) / 10, {
        maximumFractionDigits: digit,
      })

    return 0
  }, [amount, latestRate, digit])

  return (
    <AnimatePresence>
      {currency != 'USD' && (
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
          className={cn(
            !unStyled &&
              'bg-jacarta-100 font-display items-center whitespace-nowrap rounded-full px-2.5 py-1 text-xs font-semibold text-black',
            className,
          )}
        >
          {withBraces && '('}
          {!hideTilde && '~'}
          {converted} {currency}
          {withBraces && ')'}
        </motion.span>
      )}
    </AnimatePresence>
  )
}

export default CurrencyField
