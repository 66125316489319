import API from '../api'
import { useMutation } from '@tanstack/react-query'
import useUserGame from '../query/play/useUserGame'

const useDisconnectGame = (game: number) => {
  const { refetch } = useUserGame(game)

  return useMutation({
    mutationFn: async () => {
      if (!game) return

      const res = await API.post<{
        status: boolean
        message: string
        message_ar: string
      }>('handler/disconnectGame', {
        gameId: game,
      })

      if (res.status) {
        refetch()
        return
      }

      switch (res.message) {
        case 'GAME_ID_REQUIRED':
          throw new Error('Game Id is required')
        case 'ALREADY_PARTICIPATING':
          throw new Error(
            'Cannot disconnect game account while participating in any tournament. Please wait until your participation ends and try again',
          )
        case 'GAME_NOT_CONNECTED':
          throw new Error('No game account found to disconnect')
        default:
          throw new Error('An error occurred')
      }
    },
  })
}

export default useDisconnectGame
