import Link from 'next/link'
import { Image } from '@heroui/react'
import { useSession } from 'next-auth/react'

export default function Logo() {
  const { status } = useSession()
  return (
    <Link href={status === 'authenticated' ? '/dashboard' : '/'}>
      <Image
        src="/images/logo_white.png"
        alt="Gamerg.gg logo"
        className="hidden max-h-20 md:dark:block"
      />
      <Image
        src="/images/logo.png"
        alt="Gamerg.gg logo"
        className="hidden max-h-20 dark:hidden md:block"
      />
      <Image
        src="/images/logo-icon.png"
        alt="Gamerg.gg logo"
        className="block max-h-11 md:hidden"
      />
    </Link>
  )
}
