import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  loginModalShow,
  setValueAction,
  successModalShow,
} from '../redux/counterSlice'
import { useFacebookPixel } from './useFacebookPixel'

interface Props {
  key: string
  isData?: any
  params?: Record<string, any>
}
const useTracker = ({ key, isData, params = {} }: Props) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { trackEvent } = useFacebookPixel()
  const { t } = useTranslation()

  let fn
  switch (key) {
    case TrakerTypes.OpenModalLogin:
      fn = () => {
        dispatch(loginModalShow())
        trackEvent(key, params)
      }
      break
    case TrakerTypes.OpenModalLoginFromHomePage:
      fn = () => {
        dispatch(loginModalShow())
        trackEvent(key, params)
      }
      break
    case TrakerTypes.gPartnerIcon:
      fn = () => {
        trackEvent(key, params)

        /// Go To Page

        if (isData?.isSet == false) {
          dispatch(successModalShow())
          dispatch(
            setValueAction({
              type: 'SUCCESS',
              title: t('Invite Friends'),

              error: false,
              alert: false,
              success: true,

              message: `${t('Invite and manage friends to earn')} ${t(
                '10% recurring income',
              )} ${t('anytime they deposit and play.')} ${t(
                'to enjoy this feature you must verify your profile.',
              )}`,

              isButton: true,
              ButtonText: t('Invite & Earn'),
              ButtonURL: '/dashboard/invite',
            }),
          )
        } else {
          router.push('/dashboard/invite')
        }
      }
      break
    default: {
      fn = () => {
        // params will help to pass some values to tracker, like game id, user id etc
        trackEvent(key, params)
      }
    }
  }

  return fn
}

export default useTracker

export const TrakerTypes = {
  // navbar usage
  OpenModalLogin: 'OpenModalLogin',
  gPartnerIcon: 'gPartnerIcon',
  ConnectGame: 'ConnectGame',
  GPartner: 'GPartner',
  trackProfile: 'trackProfile',
  TournamentPageVisited: 'TournamentPageVisited',

  DepositFromMenu: 'DepositFromMenu',
  WithdrawFromMenu: 'WithdrawFromMenu',

  OpenModalLoginFromHomePage: 'OpenModalLoginFromHomePage',
  TryDepositWithMethods: 'TryDepositWithMethods',
  UserSignIn: 'User_sign_in',
}
