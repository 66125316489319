import React from 'react'
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@heroui/react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { joinModalHide } from '../../redux/counterSlice'
import { modalStyle } from '../../theme/nextUI'

const HowToPlayModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { joinModal, value } = useSelector((state) => state.counter)

  const hideModal = () => {
    dispatch(joinModalHide())
  }

  return (
    <Modal
      isOpen={joinModal}
      size="xl"
      onClose={hideModal}
      classNames={modalStyle}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <h5 className="modal-title">
            {value?.title} {value?.name}
          </h5>
        </ModalHeader>
        <ModalBody>
          <div>
            {value?.imageURL && (
              <Image src={value.imageURL} className="h-full w-full" alt="" />
            )}
          </div>
        </ModalBody>

        <ModalFooter className="justify-center">
          <Button
            type="button"
            size="lg"
            radius="full"
            className="bg-accent font-semibold text-white"
            onClick={hideModal}
          >
            {`${t('Close')}`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default HowToPlayModal
