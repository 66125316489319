import { useRouter } from 'next/router'
import useNavbarLinks from '@/hooks/useNavbarLinks'
import { useControlPanelStore } from '@/store/useControlPanelStore'
import { Button, Link } from '@heroui/react'

export const SidebarNavlist = () => {
  const { push } = useRouter()

  const { sidebarListData } = useNavbarLinks()
  const { setIsOpen } = useControlPanelStore()

  return (
    <nav className="flex flex-col items-start gap-2">
      {sidebarListData.map((item, i) => (
        <Button
          key={i}
          variant="light"
          className="text-jacarta-700 dark:text-jacarta-50 h-7 rounded-sm text-start font-semibold hover:bg-transparent hover:underline data-[hover=true]:bg-transparent lg:text-center"
          as={item.action ? 'button' : Link}
          href={item.href}
          onPress={() => {
            if (item.action) item.action()
            setIsOpen(false)
          }}
          startContent={item.icon}
        >
          {item.label}
        </Button>
      ))}
    </nav>
  )
}
