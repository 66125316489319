import { useQuery } from '@tanstack/react-query'
import cookies from 'js-cookie'
import axios from 'axios'
import API from '@gg/api'

export const useGeolocation = () => {
  const { data } = useQuery({
    queryKey: ['Geolocation'],
    queryFn: async () => {
      const geoCookie = cookies.get('geolocation')
      if (geoCookie) return geoCookie

      const { country_code } = await API.post<{ country_code: string }>(
        'user/getUserCountry',
      )
      if (country_code) {
        cookies.set('geolocation', country_code, { expires: 1 / 24 })
        return country_code
      }

      const { data } = await axios.get('https://ipapi.co/country_code')
      if (data) {
        cookies.set('geolocation', data, { expires: 1 / 24 })
        return data
      }

      return null
    },
    staleTime: 60000,
  })

  return data
}
