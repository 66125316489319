import { Badge, Button } from '@heroui/react'
import useTracker, { TrakerTypes } from '@/hooks/useTraker'
import { ShareIcon } from './navIcons'
import useAccountSet from '@gg/api/query/useAccountSet'

export default function GoToGPartnerButton() {
  const { data: userDetails } = useAccountSet()
  const GoToGPartner = useTracker({
    key: TrakerTypes.gPartnerIcon,
    isData: userDetails,
  })

  return (
    <Badge
      content=""
      color="danger"
      placement="top-left"
      showOutline={false}
      className="left-[13%] top-[15%]"
    >
      <Button
        size="sm"
        onPress={GoToGPartner}
        isIconOnly
        variant="faded"
        radius="full"
        className={'menu-icon-button'} // in global css
      >
        <ShareIcon />
      </Button>
    </Badge>
  )
}
