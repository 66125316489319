import { useQuery } from '@tanstack/react-query'
import { Announcement } from '@gg/shared/types'
import API from '@gg/api'

const useAnnouncements = () =>
  useQuery({
    queryKey: ['Announcements'],
    queryFn: async () => {
      const { status, data } = await API.get<{
        status: boolean
        data: Announcement
      }>('extra/getAnnouncement')

      const isNotEmpty =
        data?.announcement_en?.trim() != '' ||
        data?.announcement_ar?.trim() != ''

      return {
        status: status && isNotEmpty,
        ...data,
      }
    },
    placeholderData: {
      status: false,
      announcement_en: '',
      announcement_ar: '',
      created_at: '',
    },
  })

export default useAnnouncements
