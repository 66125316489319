import { getSession } from 'next-auth/react'
import { useTokenStore } from './store'
import { getPlatform } from './getBaseUrl'

let sessionPromise: Promise<string | undefined> | null = null

export const getToken = async () => {
  const { token, setToken } = useTokenStore.getState()
  const { PLATFORM } = getPlatform()

  if (token) return token

  if (PLATFORM == 'MOBILE') {
    const { getItem } = await import('expo-secure-store')
    const token = getItem('token')
    setToken(token!)
    return token
  }

  if (!sessionPromise) {
    sessionPromise = getSession().then((session) => {
      const secureToken = (session as any)?.secure
      if (secureToken) setToken(secureToken)

      // Clear the promise after a short delay to allow for potential
      // closely-timed subsequent requests to use the same result
      setTimeout(() => {
        sessionPromise = null
      }, 100)
      return secureToken
    })
  }

  return sessionPromise
}
