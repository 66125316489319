import React, { useRef } from 'react'
import { useRouter } from 'next/router'
import useSeachUsers from '@/hooks/query-hooks/useSeachUsers'
import useDebounceSearch from '@/hooks/useDebounceSearch'
import {
  setSearchModal,
  useAppDispatch,
  useAppSelector,
} from '@/redux/counterSlice'
import {
  Avatar,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  Spinner,
} from '@heroui/react'
import { t } from 'i18next'
import Searchbar from '../layout/cards/Searchbar'

function SearchModal() {
  const dispatch = useAppDispatch()
  const { searchModalIsOpen } = useAppSelector((state) => state.counter)
  const { searchKey, handleSearchChange } = useDebounceSearch()

  const { data, isFetching } = useSeachUsers({
    searchQuery: searchKey.trim(),
  })

  const closeModal = () => {
    dispatch(setSearchModal(false))
  }

  return (
    <Modal
      isOpen={searchModalIsOpen}
      size="md"
      placement="top"
      onClose={closeModal}
      className="shadow-none"
    >
      <ModalContent
        className="mt-2 bg-transparent"
        // onKeyDown={(e) => {
        //   if (e.key === 'Enter') {
        //     fireSearchOnEnter.current = true;
        //   }
        // }}
      >
        <Searchbar type="modal" handleSearchChange={handleSearchChange} />
        <ModalBody className="mt-4 flex flex-col items-center gap-2">
          {searchKey.length < 3 ? (
            <TextWraper>{t('Search for players')} 🧐</TextWraper>
          ) : isFetching ? (
            <div className="flex h-32 w-full items-center justify-center">
              <Spinner />
            </div>
          ) : !data?.status ? (
            <TextWraper>
              {t('No users found')}, {t('try again')}.
            </TextWraper>
          ) : (
            data?.users?.map((user, i) => (
              <UserCard key={i} user={user} handleCloseModal={closeModal} />
            ))
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SearchModal

const UserCard = ({ user, handleCloseModal }) => {
  const { push } = useRouter()
  return (
    <Card className="menu-gradient-50 dark:border-jacarta-600 border-jacarta-100 w-full border shadow-md">
      <CardBody
        className="flex cursor-pointer flex-row items-center gap-2"
        onClick={() => {
          push(`/u/${user?.username}`)
          handleCloseModal()
        }}
      >
        <Avatar
          alt="user profile picture"
          src={user?.avatar_url ?? '/images/profile/default-profile-image.jpg'}
          className="shadow-base h-10 w-10"
        />
        <div className="flex flex-1">
          <p className="text-sm font-medium">{user?.username}</p>
        </div>
      </CardBody>
    </Card>
  )
}

const TextWraper = ({ children }) => {
  return (
    <div className="menu-gradient-50 dark:border-jacarta-600 border-jacarta-100 flex h-32 w-full items-center justify-center rounded-md border shadow-md">
      <p className="text-jacarta-800 dark:text-jacarta-100 text-lg">
        {children}
      </p>
    </div>
  )
}
