import { useQuery } from '@tanstack/react-query'
import API from '../api'

interface IClaimDailyRewards {
  day: number
  status: boolean
  nextReward: Date
}

const useDailyRewardProgress = () =>
  useQuery({
    queryKey: ['credits-daily-progress'],
    queryFn: async () =>
      await API.get<IClaimDailyRewards>('dailyReward/progress'),
  })

export default useDailyRewardProgress
