import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

const useLivePhase = (liveTimes: { 1: Date; 2: Date }) => {
  const [currPhase, setCurrPhase] = useState<number>(1)

  const adjustPhase = () => {
    setCurrPhase(() => {
      if (!liveTimes) return 1

      const now = dayjs()

      if (dayjs(liveTimes[1]).diff(now) > 0) return 1
      return 2
    })
  }

  useEffect(adjustPhase, [liveTimes])

  return {
    currPhase,
    adjustPhase,
    currRemainingTime: liveTimes?.[currPhase as 1 | 2],
  }
}

export default useLivePhase
