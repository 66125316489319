import crypto from 'crypto'
import { FC, useState } from 'react'
import useConnectGame from '@/hooks/useConnectGame'
import useTracker, { TrakerTypes } from '@/hooks/useTraker'
import useUserProfile from '@gg/api/query/user/useUserProfile'
import { connectBrawlModalShow } from '@/redux/counterSlice'
import {
  Button,
  cn,
  Image,
  Select,
  SelectItem,
  SelectSection,
  SharedSelection,
} from '@heroui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import cookies from 'js-cookie'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'react-i18next'
import { FaCheck, FaX } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
import Meta from '../Meta'
import ConnectBrawlModal from '../modal/auth/connectBrawl'
import DisconnectGameModal from '../modal/disconnectGameModal'
import useUserGame from '@gg/api/query/play/useUserGame'

enum GameIds {
  LOL = 1,
  VALORANT = 2,
  DOTA = 3,
  BRAWLSTARS = 4,
  FORTNITE = 5,
}

export default function ConnectGame() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedGame, setSelectedGame] = useState<number>()

  const { connectLOL, connectVALORANT, connectDOTA, connectFORTNITE } =
    useConnectGame({ origin: 'page' })

  return (
    <>
      <Meta title={` ${t('Connect Game Account')} | ${t('Gamerg-GG')} `} />

      <p className="mt-1">
        {t(
          'Linking your account with GamerG.gg will allow us to track your performance and stats in tournaments, where we can your wins.',
        )}
      </p>

      <div className="my-5 grid gap-7 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <GameCard
          gameId={GameIds.LOL}
          img={'/images/gamelogo/lol.png'}
          onConnect={connectLOL}
          onDisconnect={(id) => setSelectedGame(id)}
        />

        <GameCard
          gameId={GameIds.VALORANT}
          img={'/images/gamelogo/valorant.png'}
          onConnect={connectVALORANT}
          onDisconnect={(id) => setSelectedGame(id)}
        />

        <GameCard
          gameId={GameIds.DOTA}
          img={'/images/gamelogo/dota2.png'}
          onConnect={connectDOTA}
          onDisconnect={(id) => setSelectedGame(id)}
        />

        <GameCard
          gameId={GameIds.BRAWLSTARS}
          img={'/images/gamelogo/brawlstars.png'}
          onConnect={() => dispatch(connectBrawlModalShow())}
          onDisconnect={(id) => setSelectedGame(id)}
        />

        <GameCard
          gameId={GameIds.FORTNITE}
          img={'/images/gamelogo/fortnite.png'}
          onConnect={connectFORTNITE}
          onDisconnect={(id) => setSelectedGame(id)}
        />
        {/* 
        <GameCard img={'/images/gamelogo/apex.png'} isComingSoon />

        <GameCard img={'/images/gamelogo/clashroyale.png'} isComingSoon />

        <GameCard img={'/images/gamelogo/overwatch2.png'} isComingSoon />

        <GameCard img={'/images/gamelogo/rocketleague.png'} isComingSoon />

        <GameCard img={'/images/gamelogo/pubg.png'} isComingSoon />

        <GameCard img={'/images/gamelogo/mobilelegends.png'} isComingSoon /> */}
      </div>

      <ConnectBrawlModal />

      <DisconnectGameModal
        game={selectedGame}
        onClose={() => {
          setSelectedGame(undefined)
        }}
      />
    </>
  )
}

export const SelectLOLServer = ({
  selectedServer,
  onSelect,
  className,
}: {
  selectedServer?: string
  onSelect: (key: SharedSelection) => void
  className?: string
}) => {
  const { t } = useTranslation()
  const { LOLServers } = useConnectGame({ origin: 'page' })

  return (
    <Select
      label={t('Select a game server')}
      variant="bordered"
      size="sm"
      className={cn('mb-2', className)}
      onSelectionChange={onSelect}
      disallowEmptySelection
      selectedKeys={selectedServer ? [selectedServer] : undefined}
    >
      {LOLServers.map(({ region, servers }) => (
        <SelectSection
          key={region}
          title={region}
          classNames={{
            heading:
              'flex w-full sticky top-1 z-20 py-1.5 px-2 bg-default-100 shadow-sm rounded-small uppercase',
          }}
        >
          {servers.map((server) => (
            <SelectItem key={server}>{server}</SelectItem>
          ))}
        </SelectSection>
      ))}
    </Select>
  )
}

interface GameCardProps {
  gameId?: number
  img?: string
  isComingSoon?: boolean
  onConnect?: () => void
  onDisconnect?: (id: number) => void
}

const GameCard: FC<GameCardProps> = ({
  gameId,
  img,
  isComingSoon,
  onConnect,
  onDisconnect,
}) => {
  const { t } = useTranslation()
  useConnectGame({ origin: 'page' })
  const { data: session } = useSession()
  const { data: userProfileData } = useUserProfile()

  const hashedEmail = getHahedEmail(
    session?.user?.email ?? userProfileData?.email,
  )

  const { data, isLoading } = useUserGame(+gameId!)

  const trackConnectGame = useTracker({
    key: TrakerTypes.ConnectGame,
    params: {
      game_id: gameId,
      user_email: hashedEmail,
      game_name: getGameName(gameId as number),
      timestamp: new Date().toISOString(),
      value: getGameName(gameId as number),
    },
  })

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      onConnect?.()
      await new Promise((resolve) => setTimeout(resolve, 500))
      trackConnectGame()
    },
  })

  const [hasLOLGameServer, setHasLOLGameServer] = useState(false)
  const selectLOLServer = ({ currentKey }: SharedSelection) => {
    setHasLOLGameServer(!!currentKey)
    if (currentKey) cookies.set('lolserver', currentKey)
    else cookies.remove('lolserver')
  }

  const isConnected = data?.status

  return (
    <div className="menu-gradient-50 dark:border-jacarta-600 border-jacarta-100 flex flex-col items-center gap-7 rounded-lg border p-6 text-center shadow-md">
      {img && <Image src={img} alt="gamename" />}

      {!isComingSoon && (
        <div className="flex w-full flex-col items-center">
          {!isConnected && gameId == 1 && (
            <SelectLOLServer onSelect={selectLOLServer} />
          )}

          <div className="flex items-center justify-center gap-2">
            <Button
              className={cn(
                'font-semibold text-white',
                isConnected ? 'bg-green' : 'bg-accent',
              )}
              onPress={() => mutate()}
              isDisabled={
                isConnected || (gameId == GameIds.LOL && !hasLOLGameServer)
              }
              isLoading={isLoading || isPending}
              startContent={isConnected && !isLoading && <FaCheck size={20} />}
            >
              {!isConnected ? t('Connect') : `${t('Connected')}`}
            </Button>
            {isConnected && (
              <Button
                className="bg-red font-semibold text-white"
                onPress={() => gameId && onDisconnect?.(gameId)}
                isLoading={isLoading || isPending}
                startContent={<FaX size={20} />}
                isIconOnly
              />
            )}
          </div>
        </div>
      )}

      <div className="dark:text-jacarta-300 font-display text-sm">
        {!isComingSoon && isConnected && <p>{data?.name}</p>}
        {isComingSoon && <p>{t('Coming Soon')}</p>}
      </div>
    </div>
  )
}

const getHahedEmail = (email?: string) => {
  if (!email) {
    return
  }
  const hashedEmail = crypto.createHash('sha256').update(email).digest('hex')
  return hashedEmail
}

const getGameName = (gameId: number) => {
  switch (gameId) {
    case GameIds.LOL:
      return 'League of Legends'
    case GameIds.VALORANT:
      return 'Valorant'
    case GameIds.DOTA:
      return 'Dota 2'
    case GameIds.BRAWLSTARS:
      return 'Brawl Stars'
    case GameIds.FORTNITE:
      return 'Fortnite'
    default:
      return 'Unknown Game'
  }
}
