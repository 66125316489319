import { useQuery } from '@tanstack/react-query'
import { LiveTournament } from '@gg/shared/types'
import API from '../../api'

const useLiveTournaments = (liveKey?: any) =>
  useQuery({
    queryKey: ['Live Tournaments', liveKey],
    queryFn: async () => {
      const { data } = await API.get<{ data: LiveTournament[] }>(
        '/play/getLiveTournaments',
      )

      return data
    },
    placeholderData: [],
  })

export default useLiveTournaments
