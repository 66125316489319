import { useQuery } from '@tanstack/react-query'
import API from '../api'

const useBonusEligible = () =>
  useQuery({
    queryKey: ['Bonus Eligble'],
    queryFn: async () => {
      await new Promise((resolve) => setTimeout(resolve, 1500))

      const { bonusEligible, userGameCount } = await API.post<{
        bonusEligible: boolean
        userGameCount: Record<number, number>[]
      }>('finances/bonusEligble')

      const map: Record<number, number> = {}

      userGameCount.forEach((game) => {
        const [key, value] = Object.entries(game)[0]!
        map[+key] = value
      })

      return { bonusEligible, userGameCount: map }
    },
  })

export default useBonusEligible
