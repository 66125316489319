import { useQuery } from '@tanstack/react-query'
import API from '../api'
import { TopWinner } from '@gg/shared/types'

interface Response {
  status: boolean
  data: TopWinner[]
}

const useTopEarners = () =>
  useQuery({
    queryKey: ['TopEarners'],
    queryFn: async () => {
      const { data } = await API.get<Response>('extra/topEarners', {
        requiresAuth: false,
      })
      return data
    },
  })

export default useTopEarners
