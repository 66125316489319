const GAMES = [
  {
    value: '1',
    label: 'League of Legends',
    image: 'League of Legends.jpg',
    isActive: true,
  },
  {
    value: '2',
    label: 'Valorant',
    image: 'Valorant.jpg',
    isActive: true,
  },
  {
    value: '5',
    label: 'Fortnite',
    image: 'Fortnite.jpg',
    isActive: true,
  },
  {
    value: '3',
    label: 'DotA',
    image: 'Dota.jpg',
    isActive: true,
  },
  {
    value: '4',
    label: 'Brawl Stars',
    image: 'Brawl Stars.jpg',
    isActive: true,
  },
  {
    value: '6',
    label: 'Apex Legends',
    image: 'Apex Legends.jpg',
    isActive: false,
  },
  {
    value: '7',
    label: 'Counter Strike',
    image: 'Counter Strike.jpg',
    isActive: false,
  },
  {
    value: '8',
    label: 'FreeFire',
    image: 'FreeFire.jpg',
    isActive: false,
  },
  {
    value: '9',
    label: 'PUBG',
    image: 'PUBG.jpg',
    isActive: false,
  },
  {
    value: '10',
    label: 'Clash Royale',
    image: 'Clash Royale.jpg',
    isActive: false,
  },
]

export default GAMES
