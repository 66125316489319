import { useRouter } from 'next/router'
import { useFacebookPixel } from '@/hooks/useFacebookPixel'
import {
  BrawlSet,
  connectBrawlModalHide,
  useAppDispatch,
  useAppSelector,
} from '@/redux/counterSlice'
import { modalStyle } from '@/theme/nextUI'
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@heroui/react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import API from '@gg/api'
import { FormInput } from '@/components/shared/FormInput'
import { useForm } from 'react-hook-form'

const ConnectBrawlModal = () => {
  const { connectBrawlModal } = useAppSelector((state) => state.counter)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const close = () => {
    dispatch(connectBrawlModalHide())
  }

  return (
    <Modal isOpen={connectBrawlModal} onClose={close} classNames={modalStyle}>
      <ModalContent>
        <ModalHeader>
          <h5 className="modal-title">{t('Connect Brawlstars')}</h5>
        </ModalHeader>
        <ModalBody className="items-center">
          <Image
            src={'/images/gamelogo/brawlstars.png'}
            alt="gamename"
            width={300}
            className="rounded-nonee"
          />

          <BrawlForm onSuccess={close} onClose={close} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const BrawlForm = ({
  onSuccess,
  onClose,
  compact = false,
}: {
  onSuccess: () => void
  onClose: () => void
  compact?: boolean
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  let router = useRouter()
  const { trackEvent } = useFacebookPixel()

  const { control, setError, handleSubmit } = useForm({
    defaultValues: {
      playerTag: '',
    },
  })

  const { mutate: connectToBrawl, isPending } = useMutation({
    mutationFn: async ({ playerTag }: { playerTag: string }) => {
      const response = await API.post<{
        err_type: string
        message: string
        message_ar: string
        status: boolean
      }>('handler/handleConnectBrawlStars', { playerTag })

      const { status, message, message_ar } = response

      if (status) {
        trackEvent('ConnectGamesBrawl', {})
        dispatch(BrawlSet())
        onSuccess()
        return response
      }

      const errorMessage = router.locale === 'en-US' ? message : message_ar
      setError('playerTag', { message: errorMessage })
    },
  })

  return (
    <form className="w-full" onSubmit={handleSubmit((e) => connectToBrawl(e))}>
      <div className="relative w-full">
        <FormInput
          label={t('text-hashtag-brawlstar')}
          control={control}
          className="w-full"
          name="playerTag"
          type="text"
          placeholder={`${t('E.g. ABCDEFG')}`}
          startContent={
            <span className="text-jacarta-700 text-lg dark:text-white">#</span>
          }
          maxLength={15}
        />
      </div>

      <footer className="my-3 flex items-center justify-center gap-2">
        <Button
          type="submit"
          isLoading={isPending}
          className="bg-green font-semibold text-white"
          size={compact ? 'md' : 'lg'}
          radius="full"
        >
          {`${t('Connect')}`}
        </Button>

        <Button
          onPress={onClose}
          className="bg-accent font-semibold text-white"
          size={compact ? 'md' : 'lg'}
          radius="full"
        >
          {`${t('Close')}`}
        </Button>
      </footer>
    </form>
  )
}

export default ConnectBrawlModal
