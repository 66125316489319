import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { ar, en } from '@gg/shared/translations'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      'en-US': {
        translation: en,
      },
      'ar-SA': {
        translation: ar,
      },
    },

    supportedLngs: ['en-US', 'ar-SA'],
    fallbackLng: 'en-US',
    detection: {
      order: [
        'cookie',
        'path',
        'querystring',
        'localStorage',
        'htmlTag',
        'subdomain',
      ],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },

    react: { useSuspense: true },
  })

export default i18n
