import { useRouter } from 'next/router'
import DiscordButton from '@/components/navbar/DiscordButton'
import Announcements from '@/components/play/announcements'
import VerifyAccount from '@/components/VerifyAccount'
import DefaultEndbar from './defaultEndbar'

function Endbar() {
  const { asPath } = useRouter()

  const pathsToHideIn = ['/admonitor', '/account-settings']

  if (pathsToHideIn.some((path) => asPath.includes(path))) {
    return <></>
  }

  return (
    <aside className="sticky-0 border-s-1 dark:border-jacarta-700 border-jacarta-100 dark:bg-jacarta-800 top-[64px] z-40 hidden h-[calc(100vh-64px)] w-80 flex-col gap-2 bg-[#f7f5ff] px-2 py-3 shadow-lg md:sticky md:flex">
      <VerifyAccount />
      <div className="flex-1 overflow-auto px-1">
        <DefaultEndbar />
      </div>

      <div className="space-y-2 p-1">
        <Announcements />
        <DiscordButton text="Talk to us on Discord" className="w-full" />
      </div>
    </aside>
  )
}

export default Endbar
