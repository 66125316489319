import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import useAnnouncements from '@gg/api/query/useAnnouncements'
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@heroui/react'
import { FaInfo } from 'react-icons/fa6'
import DiscordButton from './navbar/DiscordButton'
import Announcements from './play/announcements'

export default function FloatingButtons() {
  const { data } = useAnnouncements()
  const [isOpen, setIsOpen] = useState(false)

  const onResize = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <div className="fixed bottom-4 end-4 z-[49] flex flex-col gap-2 md:hidden">
      {data?.status && (
        <Popover
          placement="top-end"
          size="lg"
          classNames={{
            content: '!p-0 rounded-md w-72 xs:w-80',
          }}
          isOpen={isOpen}
          onOpenChange={setIsOpen}
        >
          <PopoverTrigger>
            <Button
              variant="faded"
              size="md"
              className="border-[#ffe69c] bg-[#fff3cd] text-[#664d03]"
              radius="full"
              startContent={<FaInfo size={18} />}
              isIconOnly
            />
          </PopoverTrigger>
          <PopoverContent>
            <Announcements />
          </PopoverContent>
        </Popover>
      )}

      <DiscordButton isIconOnly />
    </div>
  )
}
