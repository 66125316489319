import { useQuery } from '@tanstack/react-query'
import API from '../api'

interface AccountSet {
  hasCountry: boolean
  hasDob: boolean
  hasPhone: boolean
  isSet: boolean
  status: boolean
  email: string | undefined

  banned?: boolean
  payloader?: {
    banDetails: {
      reason_ar: string
      reason_en: string
      since: string
    }
  }
}

const useAccountSet = (checkAccountSet?: any) =>
  useQuery<AccountSet>({
    queryKey: ['Account Set', checkAccountSet],
    queryFn: async () => await API.post('auth/accountSet'),
  })

export default useAccountSet
