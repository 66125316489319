import { ReactNode } from 'react'
import Image from 'next/image'
import { cn } from '@heroui/react'

const Currency = ({
  isCredits = false,
  children,
  className,
}: {
  isCredits?: boolean
  children: ReactNode
  className?: string
}) => {
  return (
    <div className={cn('font-display flex items-center gap-1', className)}>
      {isCredits && (
        <Image
          src="/images/gg-credit/GG credits.png"
          width={24}
          height={24}
          alt="gg credit"
        />
      )}

      <span>
        {!isCredits && '$'}
        {children}
      </span>
    </div>
  )
}

export default Currency
