import { createStore } from 'zustand/vanilla'

interface TokenStore {
  token: string | undefined
  setToken: (token: string) => void
}

export const useTokenStore = createStore<TokenStore>((set) => ({
  token: undefined,
  setToken: (token) => set({ token }),
}))
