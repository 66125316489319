import API from '@gg/api'
import { Balance, useBalanceStore } from '@/store/useBalance'

const useUpdateBalance = () => {
  const { setBalance } = useBalanceStore()

  const updateBalance = async (amountToCheck?: number) => {
    const response = await API.post<Balance>('extra/checkUserBalance', {
      amountToCheck,
    })

    if (response) setBalance(response)
    return true
  }

  return { updateBalance }
}

export default useUpdateBalance
