import { useRouter } from 'next/router'
import { Button } from '@heroui/react'
import { t } from 'i18next'
import { useSession } from 'next-auth/react'
import useTracker, { TrakerTypes } from '../../hooks/useTraker'

export default function LoginButton({ classNames = '', size = 'md' }) {
  const { status } = useSession()
  const { push } = useRouter()
  // trakers
  // const doLogin = useTracker({ key: TrakerTypes.OpenModalLogin });
  const doLogin = () => push('/login')

  return (
    status == 'unauthenticated' && (
      <Button
        size={size ?? 'md'}
        radius="full"
        className={`font-display w-52 cursor-pointer bg-accent font-semibold text-white transition-all hover:bg-accent-dark 2xl:w-60 ${classNames}`}
        onPress={doLogin}
      >
        {t('Login / Signup')}
      </Button>
    )
  )
}
