import nodeAPI from '@/lib/nodeapi'
import { setEfawaterkomBilNum, useAppDispatch } from '@/redux/counterSlice'
import { useMutation } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

interface EFawaterkomrResponse {
  BillingNo: string
  depositAmount: string
  message: string
  success: boolean
}
const useCreateEfawatercom = () => {
  const { data: session } = useSession()
  const dispatch = useAppDispatch()
  const mutation = useMutation({
    mutationFn: async ({ amount }: { amount: number }) => {
      const res: EFawaterkomrResponse = await nodeAPI(
        'POST',
        session?.secure,
        `finances/create_efawaterkom_payments`,
        {
          amount,
          paymentMethod: 'eFawateerkom',
        },
      )

      if (res.success) {
        dispatch(setEfawaterkomBilNum(res.BillingNo))
      }
      return res
    },
  })
  return mutation
}

export default useCreateEfawatercom
