import { useQuery } from '@tanstack/react-query'
import API from '../../api'

const useHasLiveTours = (liveKey?: any) => {
  const { data, isLoading } = useQuery<{
    tournamentIds: number[]
    status: boolean
  }>({
    queryKey: ['Has Live Tournament', liveKey],
    queryFn: async () => {
      const { status, tournamentIds } = await API.get<{
        status: boolean
        tournamentIds: number[]
      }>('play/hasLiveTournament')

      return {
        status,
        tournamentIds,
      }
    },
  })

  return {
    hasLive: data?.status,
    tournamentIds: data?.tournamentIds,
    isLoading,
  }
}

export default useHasLiveTours
