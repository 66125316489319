import { useEffect, useRef } from 'react'
import { cn } from '@heroui/react'
import { CountryCode } from 'libphonenumber-js'
import { useTranslation } from 'react-i18next'
import PhoneInputX from 'react-phone-input-2'

interface Props {
  phone: string
  phoneCountry: string
  setPhone: (phone: string) => void
  setPhoneCountry: (phoneCountry: CountryCode) => void
  placeholder?: string
  label?: string
  onEnter?: () => void
  isInvalid?: boolean
  errorMessage?: string
  disabled?: boolean
}

const PhoneInput = ({
  phone,
  phoneCountry,
  setPhone,
  setPhoneCountry,
  placeholder = 'Enter mobile number',
  label = 'Enter mobile number',
  onEnter,
  isInvalid = false,
  errorMessage,
  disabled = false,
}: Props) => {
  const { t } = useTranslation()
  const phoneRef = useRef<any>()

  useEffect(() => {
    if (!phoneRef.current || !phoneRef.current?.state.selectedCountry.iso2)
      return
    const pc = phoneRef.current?.state.selectedCountry.iso2.toUpperCase()
    setPhoneCountry(pc)
  }, [phone, setPhoneCountry])

  return (
    <div className="space-y-1">
      <label
        className={cn(
          'font-display text-start text-sm text-foreground',
          isInvalid && 'text-danger',
        )}
        htmlFor="phoneNumber"
      >
        {t(label)}
      </label>
      <PhoneInputX
        // @ts-ignore
        ref={phoneRef as any}
        country={phoneCountry ?? 'jo'}
        excludeCountries={['il']}
        placeholder={`${t(placeholder)}`}
        containerClass={cn('force-ltr !z-20 w-full')}
        inputClass={cn(
          'dark:!bg-jacarta-700 min-w-full !rounded-xl !border-2 !pb-1.5 !pt-2 shadow-md focus:!ring-0 dark:!text-white',
          isInvalid
            ? '!border-danger text-danger'
            : 'dark:!border-jacarta-500 hover:!border-black/50 focus:!border-black',
        )}
        dropdownClass="!fixed dark:!bg-jacarta-700 [&>li:hover]:dark:!bg-jacarta-600 [&>li]:transition-colors"
        inputProps={{ id: 'phoneNumber' }}
        value={phone}
        onChange={setPhone}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onEnter?.()
        }}
        disabled={disabled}
      />
      {errorMessage && isInvalid && (
        <div className="text-danger text-xs">{errorMessage}</div>
      )}
    </div>
  )
}
export default PhoneInput
