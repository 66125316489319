import { Card, CardBody, cn } from '@heroui/react'
import { UserPublicProfile } from '@gg/shared/types'
import { getLetterColor } from '@/lib/helpers'
import RankTooltip from '@/components/RankTooltip'

interface Props {
  user: UserPublicProfile & { displayName: string }
  is_current_user?: boolean
}

function UserImageCardSM({ user, is_current_user }: Props) {
  return (
    <Card className={cn('w-full rounded-lg bg-transparent p-0')} isBlurred>
      <CardBody className="flex-row items-center gap-2 p-0">
        <div className={cn('relative', 'h-12 w-12')}>
          <img
            alt="user profile_pic"
            className={cn(
              'z-10 h-full w-full rounded-lg object-cover',
              !user?.profile_pic && 'p-1.5',
            )}
            // height={300}
            src={user?.profile_pic ?? '/images/gamelogo/small/gamerg-white.png'}
            style={{
              backgroundColor: user?.profile_pic
                ? ''
                : getLetterColor(user?.displayName?.[0] ?? ''),
            }}
          />
        </div>
        <h2 className="me-auto text-sm font-semibold text-white lg:text-[18px]">
          {user?.displayName}
        </h2>

        <RankTooltip userRank={user?.rank} size={45} />
      </CardBody>
    </Card>
  )
}

export default UserImageCardSM
