import { useState } from 'react'
import { useRouter } from 'next/router'
import PhoneInput from '@/components/PhoneInput'
import { useYup } from '@/hooks/useYup'
import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Switch,
} from '@heroui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { useSession } from 'next-auth/react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'react-phone-input-2/lib/bootstrap.css'
import { useDispatch } from 'react-redux'
import {
  PhoneSet,
  setPhoneNumberModalHide,
  useAppSelector,
} from '../../redux/counterSlice'
import { modalStyle } from '../../theme/nextUI'
import nodeAPI from './../../lib/nodeapi'

const SetPhoneNumberModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { setPhoneNumberModal } = useAppSelector((state) => state.counter)

  const closeModal = async () => {
    dispatch(setPhoneNumberModalHide())
  }

  return (
    <Modal
      isOpen={setPhoneNumberModal}
      onClose={closeModal}
      classNames={modalStyle}
      scrollBehavior="inside"
      size="lg"
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <h5 className="modal-title">{t('Mobile Number')}</h5>
        </ModalHeader>
        <ModalBody>
          <PhoneNumberForm onSuccess={closeModal} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SetPhoneNumberModal

export const PhoneNumberForm = ({ onSuccess, compact = false }) => {
  const { data: session, status } = useSession()
  const router = useRouter()
  const { Yup } = useYup()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isWhatsappChecked, setIsWhatsappChecked] = useState(true)
  const [disableNotification, setDisableNotification] = useState(false)
  const [phoneCountry, setPhoneCountry] = useState<string>('jo')

  const { data: thePhone } = useQuery({
    queryKey: ['Phone Number'],
    queryFn: async () => {
      const { thePhone, phoneCode } = await nodeAPI(
        'GET',
        session?.secure,
        'auth/hasCountryAndDate',
      )

      setPhoneCountry(phoneCode)

      return thePhone ?? ''
    },
    enabled: status == 'authenticated',
  })

  const isValidPhone = (v: string) => {
    try {
      const res = parsePhoneNumber('+' + v, {
        defaultCountry: phoneCountry?.toUpperCase() as CountryCode,
      })
      return res.isValid()
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const schema = Yup.object({
    phoneNumber: Yup.string()
      .required()
      .test('mobile-valid', `${t('Mobile_No_Err')}`, isValidPhone)
      .label(t('Mobile_No')),
  })

  const { handleSubmit, control, getValues, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    values: {
      phoneNumber: thePhone,
    },
  })

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: async () => {
      const phoneNumber = getValues('phoneNumber')

      if ((!phoneNumber || !phoneCountry) && !disableNotification)
        throw new Error(
          `Invalid phone number, if you dont want to share your number please select 'I dont want to get notified about prizes'`,
        )

      if (
        (phoneNumber.length < 6 || phoneNumber.length > 15) &&
        disableNotification === false
      )
        throw new Error('Invalid phone number, please check your entry')

      const { status, message } = await nodeAPI(
        'POST',
        session?.secure,
        'profile/setPhoneNumber',
        {
          whatsapp_number: phoneNumber,
          sms_country_code: phoneCountry,
          sms_enabled: isWhatsappChecked ? 1 : 0,
          private_row: disableNotification ? 1 : 0,
        },
      )

      if (!status) throw new Error(t(message) as string)

      dispatch((PhoneSet as any)())
      onSuccess()
      reset()
      setPhoneCountry('JO')
    },
  })

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        if (disableNotification) {
          mutate()
          return
        }

        handleSubmit((mutate as any)())
      }}
    >
      {isError && (
        <div
          className="bg-red border-red mb-3 rounded border px-4 py-3 text-white"
          role="alert"
        >
          {t(error.message)}
        </div>
      )}

      <p className="text-red mb-2 text-center text-sm">
        {t('Warning: You cannot change your phone number after you set it')}
      </p>

      <div className="flex flex-col gap-1">
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field, formState }) => (
            <>
              <PhoneInput
                phone={field.value}
                phoneCountry={phoneCountry}
                setPhone={field.onChange}
                setPhoneCountry={setPhoneCountry}
                disabled={!!thePhone}
              />
              <span className="text-sm text-red-500">
                <ErrorMessage name="phoneNumber" errors={formState.errors} />
              </span>
            </>
          )}
        />
      </div>

      <div className="mt-3 flex flex-col gap-1 text-start">
        <Switch
          size="sm"
          isSelected={isWhatsappChecked}
          onValueChange={() => setIsWhatsappChecked((v) => !v)}
          isDisabled={disableNotification}
        >
          {t('Enable WhatsApp Messages')}
        </Switch>

        <Switch
          size="sm"
          isSelected={disableNotification}
          onValueChange={() =>
            setDisableNotification((v) => {
              setIsWhatsappChecked(v)
              return !v
            })
          }
        >
          {t('I dont want to get notified about prizes')}
        </Switch>
      </div>

      <footer className="pt-5 text-center">
        <Button
          type="submit"
          size={compact ? 'md' : 'lg'}
          radius={compact ? 'md' : 'full'}
          className="bg-accent font-semibold text-white"
          isLoading={isPending}
          isDisabled={!!thePhone}
        >
          {t('Continue')}
        </Button>
      </footer>
    </form>
  )
}
