import { useMemo } from 'react'
import Currency from '@/components/Currency'
import TournamentHeader from '@/components/play/tournamentHeader'
import { formatNumber } from '@gg/shared/utils/helpers'
import { cn, Image } from '@heroui/react'
import { useTranslation } from 'react-i18next'
import { LuClock } from 'react-icons/lu'
import { MdCheckCircleOutline } from 'react-icons/md'
import useLivePhase from '@gg/shared/hooks/useLivePhase'
import { LiveTournament } from '@gg/shared/types'
import LiveCountdown from './liveCountdown'

const LiveCard = ({
  tournament,
  onSelect,
}: {
  tournament: LiveTournament
  onSelect?: () => void
}) => {
  const { t } = useTranslation()

  const {
    liveTimes,
    template,
    total_prize = 0,
    entry_fee = 0,
    bracket_id,
  } = tournament

  const { currPhase, adjustPhase, currRemainingTime } = useLivePhase(liveTimes)

  const phaseDetails = useMemo(() => {
    const phases = [
      {
        label: t('Playing'),
        image: (
          <Image
            src="/images/icons/loading2.gif"
            width={20}
            height={20}
            alt="Game Icon"
            className="shrink-0"
          />
        ),
      },
      {
        label: t('Scoring'),
        image: (
          <Image
            src="/images/icons/accent-loader.png"
            className="shrink-0 animate-spin"
            style={{ animationDuration: '5s' }}
            width={20}
            height={20}
            alt="Game Icon"
          />
        ),
      },
      {
        label: t('Final Score'),
        image: <MdCheckCircleOutline size={20} className="text-accent" />,
      },
    ]

    return phases[currPhase - 1]
  }, [currPhase, t])

  if (!template) return null

  return (
    <div
      className="bg-jacarta-700 shadow-medium relative flex h-auto w-full justify-stretch rounded-lg p-3 text-sm"
      onClick={() => onSelect?.()}
    >
      <Image
        src={template?.img_url}
        alt="Tournament Image"
        classNames={{
          img: 'absolute top-0 left-0 z-0 w-full h-full object-cover select-none pointer-events-none',
        }}
        disableSkeleton
        loading="lazy"
      />

      <div className="relative z-[1] flex w-full gap-5">
        <div className="font-display w-full flex-col items-center space-y-2">
          <div className="flex flex-1 items-center justify-between gap-2">
            <TournamentHeader template={template} hidePrize />
            <span className="bg-green-success font-display shrink-0 rounded-full px-2 py-1 text-xs text-black">
              ID: {bracket_id}
            </span>
          </div>

          <div className="flex gap-2">
            <div className="flex flex-1 flex-col justify-center gap-2">
              <div className="bg-jacarta-100 flex flex-grow items-center justify-center gap-1 rounded-full px-2 py-1">
                {phaseDetails?.image}
                <span
                  className={cn(
                    'text-accent',
                    phaseDetails?.label == t('Playing') && 'text-red-500',
                  )}
                >
                  {phaseDetails?.label}
                </span>
              </div>
              <div className="bg-jacarta-100 flex flex-grow items-center justify-center gap-1 rounded-full px-2 py-1 text-black">
                <LuClock size={20} className="shrink-0" />
                {liveTimes && bracket_id && (
                  <LiveCountdown
                    key={`${bracket_id}x${currPhase}`}
                    time={currRemainingTime}
                    completionText="Already Finished"
                    onComplete={() => adjustPhase()}
                  />
                )}
              </div>
            </div>

            <div
              className={cn(
                'flex min-w-24 items-center justify-center rounded py-2 text-center text-white',
                +entry_fee > 0 ? 'bg-accent/60' : 'bg-purple/60',
              )}
            >
              <Currency isCredits={!!template?.is_credits} className="text-lg">
                {formatNumber(+total_prize)}
              </Currency>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LiveCard
