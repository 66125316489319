import { useState } from 'react'
import { useRouter } from 'next/router'
import { useYup } from '@/hooks/useYup'
import API from '@gg/api'
import {
  AccountSet,
  setRefetchProfile,
  setupAccountModalHide,
  useAppDispatch,
  useAppSelector,
} from '@/redux/counterSlice'
import { modalStyle } from '@/theme/nextUI'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  cn,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@heroui/react'
import { useMutation } from '@tanstack/react-query'
import { OTPInput, REGEXP_ONLY_DIGITS } from 'input-otp'
import Countdown from 'react-countdown'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { inputClasses } from '../shared/FormInput'
import useAccountSet from '@gg/api/query/useAccountSet'

const SetupAccountModal = () => {
  const { setupAccountModal, checkAccountSet } = useAppSelector(
    (state) => state.counter,
  )
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const router = useRouter()
  const { Yup } = useYup()

  const [step, setStep] = useState(1)
  const [code, setCode] = useState('')
  const [isIncorrectCode, setIsIncorrectCode] = useState(false)
  const [retryinterval, setRetryInterval] = useState(1)
  const [countDown, setCountDown] = useState<number | undefined>()
  const { data: userDetails } = useAccountSet(checkAccountSet)

  const {
    mutateAsync: validateDisplayName,
    isPending: isValidatingDisplayName,
  } = useMutation({
    mutationFn: async (username) => {
      try {
        Yup.string()
          .min(5)
          .max(20)
          .matches(
            /^[a-zA-Z0-9]+$/,
            t(
              'Only letters and numbers are allowed. No special characters or spaces.',
            ) as string,
          )
          .validateSync(username)
      } catch (err) {
        return false
      }

      const { unique } = await API.post<{ unique: boolean }>(
        'user/CheckDisplayName',
        { username },
      )

      return unique
    },
  })

  const { mutateAsync: validateEmail, isPending: isValidatingEmail } =
    useMutation({
      mutationFn: async (email) => {
        try {
          Yup.string().required().email().validateSync(email)
        } catch (err) {
          return false
        }

        const { unique } = await API.post<{ unique: boolean }>(
          'user/CheckEmailUnique',
          { email },
        )

        return unique
      },
    })

  const schema = Yup.object({
    displayName: Yup.string()
      .min(5)
      .max(20)
      .required()
      .test(
        'validDisplayName',
        t('The display name is already taken') as string,
        validateDisplayName as any,
      ),

    email: Yup.string()
      .email()
      .required()
      .test(
        'validEmail',
        t('The email address is already used') as string,
        validateEmail as any,
      )
      .label(t('Email')),
  })

  const { handleSubmit, control, reset, getValues } = useForm({
    resolver: yupResolver(schema),
    values: { displayName: '', email: userDetails?.email ?? '' },
  })

  const { mutate: onSubmit, isPending: isSendingVerificationEmail } =
    useMutation({
      mutationFn: async () => {
        try {
          const { displayName: username, email } = getValues()

          setCountDown(new Date().getTime() + 1000 * 60 * retryinterval)

          const response = await API.post<{
            status: boolean
            message: string
          }>('user/sendEmailVerification', { username, email })

          if (!response.status) {
            toast.error(t('Something went wrong!'))

            return
          }

          setStep(2)
        } catch (error) {
          console.error(error)
        }
      },
    })

  const {
    mutate: validateSecurityCode,
    isPending: isValidatingCode,
    data: securityCodeMessage,
  } = useMutation({
    mutationFn: async () => {
      if (code.length < 6) return

      const { displayName: username, email } = getValues()

      const response = await API.post<{
        status: boolean
        message: string
        verified: boolean
      }>('user/validateVerificationByCode', { security_code: code })

      if (!response.status) {
        setIsIncorrectCode(true)
        setTimeout(() => {
          setIsIncorrectCode(false)
        }, 2500)

        return
      }

      setStep(3)
      dispatch(AccountSet())
      dispatch(setRefetchProfile())
      router.push('/u/' + username)
      return response.status && response.verified
        ? t('Your account has already been verified')
        : t('Account has been successfully verified')
    },
  })

  const renderer = ({ seconds, minutes, completed }: any) => {
    if (completed)
      return (
        <Link
          className="cursor-pointer"
          onPress={() => {
            setRetryInterval((v) => (v == 1 ? 3 : v == 3 ? 5 : v))
            onSubmit()
          }}
        >
          {t('Resend Code')}
        </Link>
      )

    return (
      <p>
        {t('Please wait')}
        <span className="ordinal slashed-zero tabular-nums">
          {minutes > 0
            ? ` ${minutes.toString().padStart(2, '0')} ${t('minutes')} ${t('and')}`
            : ''}
          {` ${seconds.toString().padStart(2, '0') + ' ' + t('seconds')} `}
        </span>
        {t('before requesting another code.')}
      </p>
    )
  }

  async function closeModalReset() {
    setCode('')
    setStep(1)
    reset({ displayName: '', email: userDetails?.email ?? '' })
    dispatch(setupAccountModalHide())
  }

  return (
    <Modal
      isOpen={setupAccountModal}
      onClose={closeModalReset}
      classNames={modalStyle}
    >
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit as any)}>
        <ModalHeader>
          <h5 className="modal-title">{t('verify_account')}</h5>
        </ModalHeader>
        <ModalBody className="gap-3">
          {step == 1 && (
            <>
              <Controller
                control={control}
                name="displayName"
                render={({ field, fieldState: { error, invalid } }) => (
                  <>
                    <Input
                      variant="bordered"
                      value={field.value}
                      onChange={field.onChange}
                      type="text"
                      id="displayName"
                      placeholder={t('display_name_placeholder') as string}
                      classNames={inputClasses}
                      label={t('Display name')}
                      labelPlacement="outside"
                      isInvalid={invalid}
                      errorMessage={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error, invalid } }) => (
                  <>
                    <Input
                      variant="bordered"
                      value={field.value as any}
                      onChange={field.onChange}
                      type="text"
                      id="email"
                      placeholder={t('email_address_placeholder') as string}
                      classNames={inputClasses}
                      label={t('Email')}
                      labelPlacement="outside"
                      isInvalid={invalid}
                      errorMessage={error?.message}
                      isDisabled={!!userDetails?.email}
                    />
                  </>
                )}
              />
            </>
          )}

          {step == 2 && (
            <div>
              <p className="mb-5">
                {t(`We've sent a security code to`)}
                <b>{` ${getValues('email') || ''}, `}</b>
                {t(`Please enter it below to complete your account setup.`)}
              </p>

              <div className="flex flex-col items-center justify-center gap-2">
                <OTPInput
                  autoFocus
                  value={code}
                  onChange={(v) => setCode(v)}
                  maxLength={6}
                  pattern={REGEXP_ONLY_DIGITS}
                  className="!w-full"
                  textAlign="center"
                  inputMode="numeric"
                  render={({ slots }) => (
                    <div className="flex items-center justify-center" dir="ltr">
                      {slots.slice(0, 6).map(({ isActive, char }, idx) => (
                        <div
                          key={idx}
                          className={cn(
                            'relative h-14 w-10 text-[2rem]',
                            'flex items-center justify-center',
                            'transition-all duration-300',
                            'border-jacarta-200 dark:border-jacarta-600 border-y border-e first:rounded-s-md first:border-s last:rounded-e-md',
                            'group-hover:border-ring-accent group-focus-within:border-ring-accent ring-inset',
                            { 'ring-2 ring-accent': isActive },
                            {
                              'border-red-500 bg-red-50/75 ring-red-500':
                                isIncorrectCode,
                            },
                          )}
                        >
                          {char !== null && <div>{char}</div>}
                        </div>
                      ))}
                    </div>
                  )}
                  onComplete={() => {
                    validateSecurityCode()
                  }}
                />

                <Countdown
                  key={countDown}
                  date={countDown}
                  renderer={renderer}
                />
              </div>
            </div>
          )}

          {step == 3 && (
            <div className="flex flex-col items-center justify-center gap-2">
              <Image
                src="/images/payments/status/success.png"
                width={120}
                alt="Status"
              />
              <h2 className="font-display text-lg">{t('Account Verified')}</h2>
              <p className="text-sm">{securityCodeMessage}</p>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="items-center justify-center">
          {step == 1 && (
            <Button
              type="submit"
              className="disabled:bg-disabled rounded-full bg-accent font-semibold text-white disabled:text-black"
              isLoading={isSendingVerificationEmail}
              isDisabled={isValidatingDisplayName}
            >
              {t('Continue')}
            </Button>
          )}

          {step == 2 && (
            <>
              <Button
                variant="light"
                className="rounded-full font-semibold"
                onPress={() => setStep(1)}
              >
                {t('Back')}
              </Button>
              <Button
                type="button"
                className="disabled:bg-disabled rounded-full bg-accent font-semibold text-white disabled:text-black"
                onPress={() => validateSecurityCode()}
                isLoading={isValidatingCode}
                isDisabled={code.length < 6}
              >
                {t('Verify')}
              </Button>
            </>
          )}

          {step == 3 && (
            <>
              <Button
                type="button"
                className="disabled:bg-disabled rounded-full bg-accent font-semibold text-white disabled:text-black"
                onPress={closeModalReset}
                isLoading={isValidatingCode}
              >
                {t('Close')}
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SetupAccountModal
