import Image from 'next/image'

const Gem = ({ text = '', size = 24 }: { text?: string; size?: number }) => {
  return (
    <span className="text-purple inline-flex items-center">
      {text}
      <Image src="/images/gem/gem.png" width={size} height={size} alt="Gem" />
    </span>
  )
}

export default Gem
