import { useControlPanelStore } from '@/store/useControlPanelStore'

import { Card, CardBody } from '@heroui/react'
import Image from 'next/image'
import UserImageCardSM from './UserImageCardSM'
import { UserPublicProfile } from '@gg/shared/types'
import Link from 'next/link'

interface Props {
  user: UserPublicProfile & { displayName: string }
  is_current_user?: boolean
}

function UserInofCardSM({ user, is_current_user }: Props) {
  const { isOpen, setIsOpen } = useControlPanelStore()


  return (
    <Link href={user?.username ? `/u/${user?.username}` : `/u/verify`} className='w-full'>
      <Card className="w-full rounded-none border-none">
        <CardBody
          className="relative flex-1 cursor-pointer p-4"
          onClick={() => {
          if (isOpen) setIsOpen(false)
        }}
      >
        <Image
          alt="user cover image"
          className="w-full object-cover"
          // height={200}
          fill
          src={user?.cover_pic ?? '/images/profile/cover-image.webp'}
        />

        <UserImageCardSM user={user} />
      </CardBody>
    </Card>
    </Link>
  )
}

export default UserInofCardSM
