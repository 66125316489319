import React from 'react'
import { setSearchModal, useAppDispatch } from '@/redux/counterSlice'
import { Button } from '@heroui/react'
import { IoMdSearch } from 'react-icons/io'

function SearchButton() {
  const dispatch = useAppDispatch()

  return (
    <Button
      isIconOnly
      variant="bordered"
      radius="full"
      size="sm"
      className="border-accent"
      onClick={() => dispatch(setSearchModal(true))}
    >
      <IoMdSearch fontSize={'18px'} className="text-accent" />
    </Button>
  )
}

export default SearchButton
