import { ReactNode, useEffect } from 'react'
import useConnectAffiliate from '@/hooks/useConnectAffiliate'
import useSetTimezone from '@/hooks/useSetTimezone'
import useUpdateBalance from '@/hooks/useUpdateBalance'
import * as Sentry from '@sentry/nextjs'
import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import Next13ProgressBar from 'next13-progressbar'
import Footer from './footer'
import Endbar from './layout/sidebar/Endbar'
import Sidebar from './layout/sidebar/Sidebar'
import BonusModal from './modal/bonusModal'
import CreditModal from './modal/CreditModal'
import HowToPlayModal from './modal/howToPlayModal'
import LoginModal from './modal/loginModal'
import _1D3Modal from './modal/payments/_1D3'
import DepositModal from './modal/payments/DepositModal'
import SearchModal from './modal/SearchModal'
import SetCountryOfResidenceModal from './modal/setCountryOfResidenceModal'
import DOBModal from './modal/setDOBModal'
import SetPhoneNumberModal from './modal/setPhoneNumberModal'
import SetupAccountModal from './modal/setupAccountModal'
import SuccessClaimModal from './modal/SuccessClaimModal'
import SuccessModal from './modal/successModal'
import MainNavbar from './navbar/Navbar'
import VerifyAccount from './VerifyAccount'
import useCampaignTracking from '@/hooks/useCampaignTracking'

export default function Layout({ children }: { children: ReactNode }) {
  const { data: session, status } = useSession()
  const { updateBalance } = useUpdateBalance()
  useConnectAffiliate()
  useSetTimezone()
  useCampaignTracking()

  useQuery({
    queryKey: ['Balance'],
    queryFn: () => updateBalance(),
    enabled: status == 'authenticated',
  })

  useEffect(() => {
    if (!session?.user) return
    const user = session?.user

    ;(window as any).clarity(
      'identify',
      user?.email ?? 'guest',
      user?.name ?? 'guest',
    )
    Sentry.setUser({
      username: user?.name ?? 'guest',
      email: user?.email ?? 'guest',
    })
  }, [session, status])

  return (
    <>
      <Next13ProgressBar
        height="4px"
        color="#8358FF"
        options={{ showSpinner: false }}
        showOnShallow
      />

      <MainNavbar />
      <SuccessModal />
      <LoginModal />
      <SetupAccountModal />
      <SetPhoneNumberModal />
      <SetCountryOfResidenceModal />
      <HowToPlayModal />
      <DOBModal />
      <BonusModal />
      <DepositModal />
      <CreditModal />
      <SuccessClaimModal />
      <SearchModal />
      <_1D3Modal />

      <VerifyAccount
        className="w-full rounded-none md:hidden"
        disableAnimation
      />

      <div className="flex">
        {/* Sidebar */}
        {status === 'authenticated' ? <Sidebar /> : null}

        {/* Main Content */}
        <main className="min-w-0 flex-1">
          {children}
          <Footer />
        </main>

        {status === 'authenticated' ? <Endbar /> : null}
      </div>
    </>
  )
}
