import { useEffect } from 'react'
import {
  Button,
  cn,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@heroui/react'
import { useTranslation } from 'react-i18next'
import { modalStyle, primaryButton } from '../../theme/nextUI'
import useDisconnectGame from '@gg/api/mutation/useDisconnectGame'
import { toast } from 'react-toastify'

const DisconnectGameModal = ({
  game,
  onClose,
}: {
  game: number | undefined
  onClose: () => void
}) => {
  const { t } = useTranslation()

  const { mutate, isPending, reset } = useDisconnectGame(+game!)

  const disconnectGame = () => {
    mutate(undefined, {
      onSuccess: () => {
        toast.success(t('Your game was disconnected successfully'))
        onClose()
      },
      onError: (error) => {
        toast.error(t(error.message))
        onClose()
      },
    })
  }

  useEffect(() => {
    if (!game) reset()
  }, [game, reset])

  return (
    <Modal isOpen={!!game} onClose={onClose} classNames={modalStyle}>
      <ModalContent>
        <ModalHeader>
          <h5 className="modal-title">{t('Disconnect Game')}</h5>
        </ModalHeader>
        <ModalBody className="flex flex-row [&>p]:my-auto">
          <div className="flex flex-col gap-2">
            <p className="text-red">
              {t(
                'This game account will always be linked to this GamerG account',
              )}
            </p>
            <p>{t('Are you sure you want to disconnect the game?')}</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            radius="full"
            className={primaryButton}
            onPress={() => disconnectGame()}
            isLoading={isPending}
          >
            {t('Disconnect')}
          </Button>
          <Button
            size="lg"
            radius="full"
            className={cn(primaryButton, 'bg-red')}
            onPress={onClose}
            isDisabled={isPending}
          >
            {t('Cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DisconnectGameModal
