import React, { useState } from 'react'
import { useRouter } from 'next/router'
import {
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@heroui/react'
import { t } from 'i18next'
import { getSession, useSession } from 'next-auth/react'
import { useDispatch } from 'react-redux'
import useNotifications from '../../hooks/useNotifications'
import nodeAPI from '../../lib/nodeapi'
import { setDashboardTab } from '../../redux/counterSlice'
import TimeZoneField from '../TimezoneField'
import { NotificationIcon } from './navIcons'

const Notifications = () => {
  const { notificationsList, unseenCount } = useNotifications()
  const router = useRouter()
  const { data: session, status } = useSession()
  const dispatch = useDispatch()

  const pushURL = (redirect) => {
    router.push(redirect)
  }

  const handleSeen = async () => {
    if (unseenCount > 0) {
      await nodeAPI(
        'POST',
        session.secure,
        'user/markAllNotificationAsSeen',
        {},
      )
    }
  }

  return (
    <Dropdown
      onOpenChange={(isOpen) => {
        if (isOpen) {
          handleSeen()
        }
      }}
      classNames={{
        content: 'menu-gradient',
      }}
    >
      <DropdownTrigger>
        <Button
          isIconOnly
          variant="faded"
          radius="full"
          className={'menu-icon-button overflow-visible p-4'} // in global css
          size="sm"
          aria-label="notifications-icon"
        >
          <Badge
            content=""
            color="danger"
            placement="top-left"
            showOutline={false}
            className={`${unseenCount > 0 ? '' : 'hidden'}`}
          >
            <NotificationIcon />
          </Badge>
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="notifications-menu"
        variant="flat"
        itemClasses={{
          base: [
            'text-jacarta-800 dark:text-jacarta-50',
            'data-[hover=true]:bg-transparent',
            'dark:data-[hover=true]:bg-transparent',
          ],
        }}
        disabledKeys={['title']}
      >
        {notificationsList?.length && (
          <DropdownItem key={'title'}>
            <span className="text-xl font-semibold text-black dark:text-white">
              {t('Notifications')}
            </span>
          </DropdownItem>
        )}

        {notificationsList?.length
          ? notificationsList?.map((item) => (
              <DropdownItem
                key={item.id}
                className="px-0 py-0"
                onClick={() => {
                  if (item.redirect?.includes('history')) {
                    return pushURL('/dashboard/account-settings?t=history')
                  }
                  // if we are in dahsboard page
                  if (
                    router.pathname.includes('/dashboard') &&
                    !router.pathname.includes('/dashboard/wallet') &&
                    // !router.pathname.includes('dashboard/history') &&
                    !router.pathname.includes('dashboard/account-settings') &&
                    !router.pathname.includes('dashboard/invite')
                  ) {
                    if (item.redirect?.includes('#upcoming'))
                      return dispatch(setDashboardTab('joined'))
                    if (item.redirect?.includes('#live'))
                      return dispatch(setDashboardTab('live'))
                    return dispatch(setDashboardTab(''))
                  }
                  if (item.redirect?.includes('#upcoming'))
                    return pushURL('/dashboard?t=joined')
                  if (item.redirect?.includes('#live'))
                    return pushURL('/dashboard?t=live')
                  // else
                  if (item.redirect) pushURL(item.redirect)
                }}
              >
                <span className="dark:bg-jacarta-700 bg-jacarta-100 m-1 flex max-w-[350px] flex-col gap-2 overflow-hidden rounded-lg p-4 shadow-md transition-all hover:-translate-y-1">
                  <span className="font-display dark:text-jacarta-200 text-jacarta-500 line-clamp-2 block overflow-ellipsis text-sm">
                    {router.locale === 'en-US'
                      ? item.message_en
                      : item.message_ar}
                  </span>

                  <span className="font-display text-jacarta-400 block text-xs dark:text-white">
                    <TimeZoneField dateTime={item.created_at} />
                  </span>
                  <p className="font-display block text-xs text-accent">
                    {router.locale === 'en-US'
                      ? item.relativeCreatedAtEn
                      : item.relativeCreatedAtAr}
                  </p>
                </span>
              </DropdownItem>
            ))
          : null}

        {notificationsList?.length ? (
          <DropdownItem key={'/dashboard/notifications'}>
            <span
              className="block text-center font-medium text-black dark:text-white"
              onClick={() => pushURL('/dashboard/notifications')}
            >
              {t('Show all')}
            </span>
          </DropdownItem>
        ) : (
          <DropdownItem key={'no notifications'} className="cursor-default">
            <span className="text-jacarta-400 block text-center font-medium">
              {t('No notifications yet')}.
            </span>
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

export default Notifications
