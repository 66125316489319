import { create } from 'zustand'

export type Balance = {
  depositBalance: number
  prizeUserBalance: number
  creditBalance: number
}

interface BalanceState {
  balance: Balance
  setBalance: (balance: Balance) => void
}

export const useBalanceStore = create<BalanceState>()((set) => ({
  balance: {
    depositBalance: 0,
    prizeUserBalance: 0,
    creditBalance: 0,
  },
  setBalance: (balance) => set(() => ({ balance })),
}))
