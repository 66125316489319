import { useRouter } from 'next/router'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  cn,
  Image,
} from '@heroui/react'
import { t } from 'i18next'

interface DayCardProps {
  day: number
  amount: number
  status: boolean
  currentDay: number
  className?: string
}

export const DayCard = ({
  day,
  amount,
  status,
  currentDay,
  className,
}: DayCardProps) => {
  const isAbleToClaim = day === currentDay && status
  const isClaimed = (day === currentDay && !status) || day < currentDay
  return (
    <Card
      shadow="none"
      className={cn(
        `w-full font-bold text-white ${isAbleToClaim ? 'bg-accent' : 'bg-[#a8a8a8]'}`,
        className,
      )}
      radius="sm"
    >
      <CardHeader className="justify-center p-0 text-sm">
        {t('Day')} {day}
      </CardHeader>
      <CardBody className={`relative p-0`}>
        <div className={`z-10 ${!isAbleToClaim ? 'grayscale filter' : ''}`}>
          <Image
            src={`/images/gg-credit-modal/${getCreditImage(amount)}.webp`}
            alt="credit image"
            radius="none"
          />
        </div>
        {isClaimed ? (
          <div className={`absolute left-0 top-0 z-50`}>
            <Image
              src="/images/gg-credit-modal/checked.webp"
              alt="credit image"
              radius="none"
            />
          </div>
        ) : null}
      </CardBody>
      <CardFooter className="justify-center p-1 text-lg leading-none">
        X {amount}
      </CardFooter>
    </Card>
  )
}

export const DayLongCard = ({
  day,
  amount,
  status,
  currentDay,
  className,
}: DayCardProps) => {
  const isAbleToClaim = day === currentDay && status
  const isClaimed = day === currentDay && !status
  const { locale } = useRouter()
  return (
    <Card
      shadow="none"
      className={cn(
        `w-full border-2 font-bold ${isAbleToClaim ? 'border-accent text-accent' : 'border-[#a8a8a8] text-[#a8a8a8]'} text-xl`,
        className,
      )}
      radius="sm"
    >
      <CardBody className={`relative p-0`}>
        <div className="absolute left-3 top-2 z-50">
          {t('Day')} {day}
        </div>
        <div className="absolute bottom-2 right-3 z-50">X {amount}</div>
        <div
          className={`z-10 w-full ${!isAbleToClaim ? 'grayscale filter' : ''}`}
        >
          <Image
            src={`/images/gg-credit-modal/${getCreditImage(amount)}.webp`}
            alt="credit image"
            radius="none"
            className="w-full"
            style={{
              maxWidth: '100%',
            }}
            classNames={{
              img: `w-full`,
              wrapper: `w-full !max-w-full`,
            }}
          />
        </div>
        {isClaimed ? (
          <div className={`absolute start-[35%] top-1 z-50 w-[30%]`}>
            <Image
              src="/images/gg-credit-modal/checked.webp"
              alt="credit image"
              radius="none"
            />
          </div>
        ) : null}
      </CardBody>
    </Card>
  )
}

const getCreditImage = (amount: number) => {
  switch (amount) {
    case 5:
      return '1'
    case 10:
      return '2'
    case 15:
      return '5'
    default:
      return '1'
  }
}
