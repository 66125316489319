import { useRouter } from 'next/router'
import { useCurrencyStore } from '@/store/useCurrencyStore'
import { Image, Tooltip } from '@heroui/react'
import { t } from 'i18next'
import { Template, Tournament, TournamentType } from '@gg/shared/types'
import CurrencyField from '../CurrencyField'

const TournamentHeader = ({
  template,
  hidePrize = false,
  tournamentType,
  showDuration,
  tournament,
}: {
  template: Template
  hidePrize?: boolean
  tournamentType?: TournamentType
  showDuration?: boolean
  tournament?: Tournament
}) => {
  const router = useRouter()
  const { currency } = useCurrencyStore()

  return (
    <div className="flex w-full min-w-0 flex-1 items-center justify-between gap-2 text-white">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-1 items-center gap-2">
          <Tooltip
            content={
              router.locale === 'en-US'
                ? template?.game_details?.game_name_en
                : template?.game_details?.game_name_ar
            }
            color="primary"
          >
            <Image
              src={template?.game_details?.game_mode_image}
              width={20}
              height={20}
              className="rounded-none"
              alt="Game Mode"
              disableSkeleton
            />
          </Tooltip>
          <span className="font-display flex-1 truncate text-start text-lg font-medium">
            {router.locale === 'en-US' ? template.name : template.name_ar}
          </span>
        </div>
        {showDuration &&
          (template?.league == 1 && tournament?.start ? (
            <span className="font-display flex text-base font-medium">
              {new Date(tournament?.start).toLocaleDateString('es-CL', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </span>
          ) : (
            <span className="font-display flex text-base font-medium">
              {t('Duration')}
              {' : '}
              {router.locale === 'en-US'
                ? tournament?.durationTextEN
                : tournament?.durationTextAR}
            </span>
          ))}
      </div>

      {tournamentType != 'credits' && !hidePrize ? (
        <CurrencyField amount={+template.total_prize} />
      ) : (
        <></>
      )}
    </div>
  )
}

export default TournamentHeader
