import useBreakpoint from '@/hooks/useBreakpoint'
import { useControlPanelStore } from '@/store/useControlPanelStore'
import {
  Button,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@heroui/react'
import { useSession } from 'next-auth/react'
import { RiCloseFill, RiMenu5Fill } from 'react-icons/ri'
import CtrMenu from './CtrMenu'
import LoginButton from './LoginButton'
import useAccountSet from '@gg/api/query/useAccountSet'
import { t } from 'i18next'
import { MdOutlineMenu } from 'react-icons/md'

export default function LoginButtonWithMenu() {
  const { isOpen, setIsOpen } = useControlPanelStore()
  const { status } = useSession()
  const { isLess: isLessLargeScreen } = useBreakpoint('xl')
  const { isLess: isLessMd } = useBreakpoint('sm')
  const { data: userDetails } = useAccountSet()

  const isAuthenticated = status === 'authenticated' && !userDetails?.banned

  return (
    <>
      <LoginButton
        classNames={isLessMd ? 'w-fit text-xs' : ''}
        size={isLessMd ? 'sm' : 'md'}
      />
      {isAuthenticated && isLessLargeScreen && (
        <Popover isOpen={isOpen} onOpenChange={setIsOpen} shouldBlockScroll>
          <PopoverTrigger>
            <Button
              radius="full"
              // isIconOnly
              variant="faded"
              className={cn(
                'gap-1 px-2 text-base font-medium shadow-sm hover:bg-accent hover:text-white hover:opacity-100 dark:hover:bg-accent dark:hover:text-white xl:hidden',
                isOpen
                  ? 'bg-accent text-white dark:bg-accent'
                  : 'border-accent bg-white text-accent dark:bg-transparent dark:text-accent',
                'menu-step-1', // this is for steps tour
              )}
              aria-label="nav-menu-icon"
            >
              {isOpen ? (
                <RiCloseFill fontSize={'20px'} />
              ) : (
                <MdOutlineMenu fontSize={'22px'} />
              )}{' '}
              {t('Menu')}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            aria-label="Static Actions"
            className={cn('menu-gradient min-w-[350px] p-0 xl:min-w-[300px]')}
          >
            <CtrMenu isAuthenticated={isAuthenticated} />
          </PopoverContent>
        </Popover>
      )}
    </>
  )
}
