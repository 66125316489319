import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface State {
  gameId: string
  setGameId: (gameId: State['gameId']) => void
}

export const useGameStore = create<State>()(
  persist(
    (set) => ({
      gameId: '1',
      setGameId: (gameId) => set(() => ({ gameId })),
    }),
    { name: 'game-store' },
  ),
)
