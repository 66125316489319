import { useEffect, useState } from 'react'
import { Url } from 'next/dist/shared/lib/router/router'
import { useRouter } from 'next/router'

const useSafePush = () => {
  const [onChanging, setOnChanging] = useState(false)
  const handleRouteChange = () => {
    setOnChanging(false)
  }
  const router = useRouter()
  const safePush = (url: Url, as?: Url) => {
    if (onChanging) {
      return
    }
    setOnChanging(true)
    router.push(url, as)
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, setOnChanging])
  return { safePush }
}

export default useSafePush
