import React from 'react'
import { t } from 'i18next'
import { signOut } from 'next-auth/react'
import { IoGameController } from 'react-icons/io5'
import { MdLogout, MdSettings } from 'react-icons/md'

const useNavbarLinks = () => {
  const sidebarListData = [
    {
      label: t('Account Settings'),
      href: '/dashboard/account-settings',
      icon: <MdSettings fontSize={20} />,
    },
    {
      label: t('Tournaments History'),
      href: '/dashboard/account-settings?t=tournaments',
      icon: <IoGameController fontSize={20} />,
    },
    {
      label: t('Contact_Us'),
      href: '/contact',
      icon: <HelpCenterIcon />,
    },
    {
      label: t('Log Out'),
      action: signOut,
      icon: <MdLogout fontSize={20} />,
    },
  ]

  return { sidebarListData }
}

export default useNavbarLinks

const HelpCenterIcon = ({ fill, fontSize, width, height }) => (
  <svg
    fontSize={fontSize ?? '18px'}
    width={width ?? '20px'}
    height={height ?? '20px'}
    viewBox="0 0 24 24"
    fill={fill ?? 'currentColor'}
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M12 1 3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-1 6h2v2h-2V7zm0 4h2v6h-2v-6z"></path>
  </svg>
)
