import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Cookies from 'js-cookie'
import { useQuery } from '@tanstack/react-query'
import API from '@gg/api'

const useCampaignTracking = () => {
  const { query, replace, pathname } = useRouter()

  useEffect(() => {
    if (!query.c) return

    Cookies.set('c', query.c as string, {
      expires: 30,
      secure: true,
    })

    const newQuery = { ...query }
    delete newQuery.c
    replace(
      {
        pathname: pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true },
    )
  }, [query])

  return useQuery({
    queryKey: ['Refer Tracking'],
    queryFn: async () => {
      const c = Cookies.get('c')
      if (!c) return false

      await API.post<{ status: boolean }>('user/trackAdSource', {
        trackingSource: c,
      })

      Cookies.remove('c')

      return true
    },
    retry: false,
  })
}

export default useCampaignTracking
