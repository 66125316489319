export const getPlatform = () => {
  let PLATFORM = undefined
  const NEXT_API = process.env.NEXT_PUBLIC_NODE_API
  const EXPO_API = process.env.EXPO_PUBLIC_NODE_API

  if (NEXT_API) PLATFORM = 'WEB'
  if (EXPO_API) PLATFORM = 'MOBILE'

  if (!PLATFORM) throw new Error('NODE_API is not set')

  return { PLATFORM, NEXT_API, EXPO_API }
}

export const getBaseUrl = () => {
  const { PLATFORM, NEXT_API, EXPO_API } = getPlatform()

  let dev = false
  // dev = true
  const baseURL = !dev
    ? PLATFORM == 'WEB'
      ? NEXT_API
      : EXPO_API
    : 'http://127.0.0.1:3010/api/v2/'

  return baseURL
}
