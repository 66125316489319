import { Tooltip, Image } from '@heroui/react'

const RankTooltip = ({
  userRank,
  size = 24,
}: {
  userRank: { name: string; icon: string }
  size?: number
}) => {
  if (!userRank) return null

  return (
    <Tooltip
      content={userRank?.name}
      classNames={{
        content: 'py-1 px-2 shadow-xl menu-gradient font-display',
      }}
    >
      <Image
        src={userRank?.icon}
        alt={userRank?.name}
        width={size}
        height={size}
      />
    </Tooltip>
  )
}

export default RankTooltip
