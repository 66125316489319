import { useState } from 'react'
import { useRouter } from 'next/router'
import Gem from '@/components/gem'
import {
  Button,
  cn,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Spinner,
} from '@heroui/react'
import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'
import { motion } from 'framer-motion'
import { useSession } from 'next-auth/react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { FaChevronRight } from 'react-icons/fa'
import { MdContentCopy } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import nodeAPI from '../../../lib/nodeapi'
import { depositModalHide, useAppSelector } from '../../../redux/counterSlice'
import { useBalanceStore } from '../../../store/useBalance'
import { modalStyle } from '../../../theme/nextUI'
import CurrencyField from '../../CurrencyField'
import PaymentButtons from '../../wallet/PaymentButtons'

const DepositModal = () => {
  const [fromDetailsBtn, setFromDetailsBtn] = useState(false)
  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useDispatch()
  const { data: session } = useSession()
  const { setBalance, balance } = useBalanceStore()
  const { depositModal } = useAppSelector((state) => state.counter)
  const [step, setStep] = useState(1)
  const [amount, setAmount] = useState('')
  const [hasCustomAmount, setHasCustomAmount] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState('')
  const [method, setMethod] = useState()
  const [paymentInfo, setPaymentInfo] = useState({
    amount: 0,
    currency: 'USD',
  })
  const cards = [
    { name: t('Treasure'), xAmount: 50, image: '/images/deposit/3.jpg' },
    { name: t('Bag Holder'), xAmount: 20, image: '/images/deposit/2.jpg' },
    { name: t('Stone Collector'), xAmount: 10, image: '/images/deposit/1.jpg' },
  ]

  const handleAmountChange = (e) => {
    const newValue = e.target.value
    if (
      newValue === '' ||
      (Number(newValue) > 0 &&
        Number(newValue) <= 2000 &&
        !isNaN(Number(newValue)))
    ) {
      setAmount(newValue)
    }
  }

  const hideModal = () => {
    setStep(1)
    dispatch(depositModalHide())
    setAmount('')
    setMethod(null)
  }

  const handlePaymentStatus = async ({
    status,
    response,
    amount,
    currency = 'USD',
  }) => {
    setPaymentStatus(status)
    setStep(3)
    setPaymentInfo({ amount, currency })

    if (status == 'success') {
      const response = await nodeAPI(
        'POST',
        session.secure,
        'extra/checkUserBalance',
        {
          amountToCheck: 0,
        },
      )

      setBalance({
        ...balance,
        depositBalance: response.depositBalance,
        prizeUserBalance: response.prizeUserBalance,
      })
    }
  }

  const payNow = () => {
    setTimeout(() => {
      setStep(2)
      setHasCustomAmount(false)
    }, 450)
  }

  const { data: pendingPayments, isPending: pendingPaymentsIsPending } =
    useQuery({
      queryKey: [
        'Pending Payments',
        {
          secure: session?.secure,
          step,
        },
      ],
      queryFn: async () =>
        await nodeAPI(
          'POST',
          session?.secure,
          'finances/checkIfPendingPayments',
        ),
      initialData: { status: false },
      enabled: [3, 4].includes(step),
    })

  return (
    <Modal
      isOpen={depositModal}
      onClose={hideModal}
      size={
        ['CliQ', 'eFawateerkom'].includes(method) && step == 3 ? 'lg' : 'xl'
      }
      scrollBehavior="inside"
      classNames={modalStyle}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <div className="translate-y-[5px]">
              <Gem size={48} />
            </div>

            <div className="-ms-1 flex flex-col">
              <h5 className="modal-title">{t('Gem Packs')}</h5>
              <p className="text-jacarta-400 -mt-1.5 text-sm">
                {t('Add gems to your account')}
              </p>
            </div>
          </div>

          <div className="border-jacarta-100 dark:border-jacarta-600 -m-5 mt-5 flex items-center justify-between gap-3 border-t px-5 pb-1">
            {(step !== 3 || method === 'eFawateerkom') && (
              <div className="flex items-center gap-2 py-3 text-sm">
                <button
                  className={cn(step == 1 && 'font-semibold text-accent')}
                  onClick={() => setStep(1)}
                >
                  {t('Select Amount')}
                </button>

                <FaChevronRight
                  className={cn(
                    'text-jacarta-400',
                    router.locale != 'en-US' && 'rotate-180',
                  )}
                  size={12}
                />

                <button
                  className={cn(
                    step == 2 && !method && 'font-semibold text-accent',
                  )}
                  onClick={() => {
                    setStep(2)
                    setMethod(null)
                  }}
                  disabled={amount < 5}
                >
                  {t('Payment Method')}
                </button>

                {method != null && step != 1 && (
                  <>
                    <FaChevronRight
                      className={cn(
                        'text-jacarta-400',
                        router.locale != 'en-US' && 'rotate-180',
                      )}
                      size={12}
                    />
                    <button
                      className={cn(
                        step == 2 && 'font-semibold text-accent',
                        method === 'eFawateerkom' &&
                          'font-semibold text-accent',
                      )}
                      onClick={() => {
                        if (method === 'eFawateerkom') return
                        setStep(2)
                      }}
                      disabled={amount < 5 || method === 'eFawateerkom'}
                    >
                      {t(method)}
                    </button>
                  </>
                )}
              </div>
            )}

            {step == 1 && (
              <div className="[&>span]:font-display flex h-8 select-none gap-px rounded-full bg-[#090c1d] pe-1 ps-3 pt-px text-lg leading-8 tracking-wide text-white">
                <span className="text-[22px] ltr:text-[20px]">$</span>
                <span>1</span>
                <span>=</span>
                <span className="text-purple">1</span>
                <span className="shrink-0 -translate-x-[3px] translate-y-px">
                  <Gem size={24} />
                </span>
              </div>
            )}
          </div>
        </ModalHeader>
        <ModalBody>
          {step == 1 && (
            <>
              <div className="grid gap-3 sm:grid-cols-3">
                {cards.map(({ xAmount, image, name }) => (
                  <div key={xAmount}>
                    <Button
                      className={cn(
                        'rounded-large group h-auto w-full flex-1 select-none justify-between bg-[#090c1d] p-3 text-accent shadow-sm ring-0 ring-accent transition-all sm:flex-col',
                        amount == xAmount && '!ring-2',
                      )}
                      onPress={() => {
                        setAmount(xAmount)
                        setTimeout(() => {
                          setStep(2)
                          setHasCustomAmount(false)
                        }, 450)
                      }}
                    >
                      <div>
                        <h6 className="font-display text-lg font-bold">
                          {name}
                        </h6>

                        <span className="-mt-2">
                          {xAmount} {t('Gems')}
                        </span>
                      </div>

                      <div className="order-first sm:order-none">
                        <Image
                          src={image}
                          alt={name}
                          className="w-[80px] sm:w-[120px]"
                          disableSkeleton
                        />
                      </div>

                      <hr className="text-jacarta-500 hidden sm:block" />

                      <div className="flex w-28 flex-col sm:w-full">
                        <span
                          className={cn(
                            'group-hover:bg-accent-dark mt-2 inline-block w-full rounded-full border border-accent bg-accent p-1 text-lg font-bold text-white',
                          )}
                        >
                          ${xAmount}
                        </span>
                        <CurrencyField
                          unStyled
                          className="bg-secondary-50 mx-auto mt-2 rounded-full p-2 text-xs font-bold tracking-tight shadow-lg sm:hidden"
                          amount={xAmount}
                        />
                      </div>
                    </Button>

                    <CurrencyField
                      unStyled
                      className="bg-secondary-50 mt-2 hidden rounded-full p-2 text-center text-sm font-bold tracking-tight shadow-lg sm:block"
                      amount={xAmount}
                    />
                  </div>
                ))}
              </div>

              <div className="bg-jacarta-100 dark:bg-jacarta-600 relative mb-5 mt-6 h-0.5 w-full">
                <div className="dark:bg-jacarta-700 absolute left-1/2 -translate-x-1/2 -translate-y-3 select-none bg-white px-3 font-semibold uppercase">
                  {t('Or')}
                </div>
              </div>

              {!hasCustomAmount ? (
                <Button
                  className="rounded-large relative flex h-auto w-full shrink-0 justify-around bg-[#090c1d] text-white shadow-sm"
                  onPress={() => {
                    setTimeout(() => {
                      setHasCustomAmount(true)
                    }, 450)
                  }}
                >
                  <Image
                    src={'/images/deposit/4.jpg'}
                    width={90}
                    height={90}
                    alt="box"
                    disableSkeleton
                  />
                  <span className="absolute inset-0 z-20 content-center bg-black/60 text-lg font-bold uppercase tracking-tight text-accent backdrop-blur-[1px] sm:static sm:bg-transparent sm:text-xl">
                    {t('Custom Gem Amount')}
                  </span>
                  <Image
                    src={'/images/deposit/4.jpg'}
                    width={90}
                    height={90}
                    alt="box"
                    disableSkeleton
                  />
                </Button>
              ) : (
                <div className="rounded-large grid h-auto grid-cols-2 items-end gap-5 bg-[#090c1d] px-4 py-3 text-white shadow-sm">
                  <div className="flex flex-col items-start gap-1">
                    <label htmlFor="amount">{t('Enter Amount')}</label>
                    <div className="relative">
                      <input
                        type="text"
                        id="amount"
                        value={amount}
                        onChange={handleAmountChange}
                        pattern="[0-9]*"
                        className="dark:bg-jacarta-700 border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg border-2 bg-[#090c1d] px-3 py-3 pe-[2.5rem] hover:ring-2 hover:ring-accent/10 focus:ring-accent"
                        placeholder={t('Enter amount...')}
                        onKeyDown={({ key }) => {
                          if (key == 'Enter' && amount >= 5) payNow()
                        }}
                      />

                      <div className="absolute end-2 top-2.5">
                        <Gem size={30} />
                      </div>
                    </div>
                  </div>
                  <Button
                    className={cn(
                      'hover:bg-accent-dark group-hover:bg-accent-dark inline-block h-auto w-full rounded-full border border-accent bg-accent px-1 py-3 text-lg font-bold text-white transition-colors',
                    )}
                    onPress={payNow}
                    isDisabled={amount < 5}
                  >
                    {t('Pay Now')}
                  </Button>
                </div>
              )}
            </>
          )}

          {step === 2 && (
            <PaymentButtons
              onPayment={handlePaymentStatus}
              amount={amount}
              setAmount={setAmount}
              method={method}
              setMethod={setMethod}
              onClose={hideModal}
              setStep={setStep}
              setFromDetailsBtn={setFromDetailsBtn}
            />
          )}

          {/* if step 3 && method = cliq then return the new details modal */}
          {step === 3 &&
            (method === 'CliQ' ? (
              <>
                <div className="flex flex-col items-center gap-5">
                  <h2 className="text-center text-lg">
                    {t(
                      'Once the funds are received in the company account, we will process your deposit within 24 hours',
                    )}
                  </h2>

                  <div className="flex flex-col items-center rounded-lg border border-accent p-6">
                    {pendingPaymentsIsPending ? (
                      <Spinner color="secondary" />
                    ) : (
                      <>
                        <span className="text-xl">{t('CliQ Account')}</span>
                        <span className="mb-2 text-7xl font-medium leading-[72px] text-accent">
                          {t('GAMERG')}
                        </span>
                        <span className="text-[18px] font-medium">
                          {t('Transfer Amount')} :{' '}
                          {(
                            Number(
                              pendingPayments?.message?.[0]?.deposit_amount,
                            ) +
                            Number(pendingPayments?.message?.[0]?.fee_amount)
                          ).toFixed(1)}{' '}
                          {t('JOD')}
                        </span>
                      </>
                    )}
                  </div>

                  <div className="ms-2 flex flex-col self-start">
                    <span>
                      {t('Company Name')} : {t('Al Faragh Al Rakami')}
                    </span>
                    <span>
                      {t('Bank Name')} : {t('Bank al Etihad')}
                    </span>
                  </div>
                  {/* {!fromDetailsBtn && (
                    <Button
                      variant="flat"
                      className="bg-accent w-full text-white font-semibold"
                      onPress={() => setStep(4)}
                      radius="full"
                    >
                      {t('Next')}
                    </Button>
                  )} */}
                </div>
              </>
            ) : method == 'eFawateerkom' ? (
              <EFawateerkomContent />
            ) : (
              <div className="flex flex-col items-center gap-2 text-center">
                <motion.img
                  initial={{ opacity: 0, scale: 0.7, rotate: 10 }}
                  animate={{ opacity: 1, scale: 1, rotate: 0 }}
                  exit={{ opacity: 0, scale: 0.7, rotate: 10 }}
                  transition={{ duration: 0.5 }}
                  src={`/images/payments/status/${paymentStatus}.png`}
                  width={150}
                  height={150}
                  alt="Payment Successfull"
                />
                {paymentStatus == 'success' && (
                  <h6 className="text-lg font-semibold text-green-500">
                    {t('Payment Successfull')}
                  </h6>
                )}
                {paymentStatus == 'fail' && (
                  <>
                    <h6 className="text-lg font-semibold text-red-500">
                      {t('Payment Failed')}
                    </h6>

                    <div className="my-2 flex justify-end">
                      <Button
                        variant="flat"
                        className="bg-accent font-semibold text-white"
                        radius="full"
                        onPress={() => {
                          setStep(2)
                          setMethod(null)
                        }}
                      >
                        {t('Try another method')}
                      </Button>
                    </div>
                  </>
                )}

                {paymentStatus == 'pending' && (
                  <div>
                    <h6 className="text-purple text-lg font-semibold">
                      {t('Payment Pending')}
                    </h6>
                    <p className="text-jacarta-600 dark:text-jacarta-100 font-semibold">
                      {t('Your Deposit is being processed')}
                    </p>
                  </div>
                )}

                {paymentStatus != 'fail' && (
                  <>
                    <div className="grid grid-cols-2 gap-5">
                      <div>
                        <span>{t('Amount')}:</span>
                        <span className="ms-1 text-accent">
                          {pendingPayments?.message?.[0]?.deposit_amount ??
                            `${paymentInfo.amount} ${paymentInfo.currency}`}
                        </span>
                      </div>
                      <div>
                        <span>{t('Method')}:</span>
                        <span className="ms-2 capitalize text-accent">
                          {t(method)}
                        </span>
                      </div>
                    </div>

                    <div className="my-2 flex justify-end gap-2">
                      <Button
                        variant="flat"
                        // size="lg"
                        radius="full"
                        className="bg-accent font-semibold text-white"
                        onPress={hideModal}
                      >
                        {t('Close')}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DepositModal

const EFawateerkomContent = () => {
  const { efawaterkomBilNum } = useAppSelector((state) => state.counter)
  return (
    <div className="font-display text-medium mb-2">
      <div className="mb-4 flex flex-col gap-1">
        <span>1. {t('Open E-fawaterkoom and search for GamerG')}</span>
        <span>2. {t('Enter your billing number provided below')}</span>
        <span>3. {t('Input the amount and confirm the payment')}</span>
      </div>
      <div className="flex-center relative w-full rounded-md border-2 p-8 text-3xl font-bold">
        {efawaterkomBilNum ?? t('Pending')}

        <CopyToClipboard
          text={efawaterkomBilNum}
          onCopy={() => {
            toast.success(t('Copied to clipboard!'))
          }}
        >
          <Button
            size="sm"
            isIconOnly
            color="primary"
            radius="full"
            className="absolute bottom-2 end-2 w-fit"
          >
            {<MdContentCopy size={18} />}
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  )
}
