import { useRouter } from 'next/router'
import cookies from 'js-cookie'

const useConnectGame = ({ origin }: { origin: string | null }) => {
  const router = useRouter()

  const connectLOL = () => {
    if (origin) cookies.set('connectOrigin', origin)

    const clientId = '012a1b6f-2251-4317-8278-52256e8b3a8f'
    const redirectUri = encodeURIComponent('https://gamerg.gg/riot/callback')
    const scope = 'openid cpid'
    const riotAuthUrl = `https://auth.riotgames.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&prompt=login`

    cookies.set('gameType', 'LOL')

    router.push(riotAuthUrl)
  }

  const connectVALORANT = () => {
    if (origin) cookies.set('connectOrigin', origin)

    const clientId = '012a1b6f-2251-4317-8278-52256e8b3a8f'
    const redirectUri = encodeURIComponent('https://gamerg.gg/riot/callback')
    const scope = 'openid cpid'
    const riotAuthUrl = `https://auth.riotgames.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&prompt=login`

    cookies.set('gameType', 'VALORANT')

    router.push(riotAuthUrl)
  }

  const connectDOTA = () => {
    if (origin) cookies.set('connectOrigin', origin)

    router.push(process.env.NEXT_PUBLIC_NODE_STORAGE + '/auth/steam')
  }

  const connectFORTNITE = () => {
    if (origin) cookies.set('connectOrigin', origin)

    const clientId = 'xyza7891xOKKKgM6nJUpTx6b8SZUXrdR'
    const redirectUri = encodeURIComponent(
      'https://gamerg.gg/epic/callback/game',
    )
    const scopes = encodeURIComponent('basic_profile  presence')
    const responseType = 'code'

    const oauthUrl = `https://www.epicgames.com/id/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scopes}&prompt=login`

    router.push(oauthUrl)
  }

  const LOLServers = [
    {
      region: 'Europe',
      servers: ['EUNE', 'EUW', 'ME1', 'TR', 'RU'],
    },
    {
      region: 'Americas',
      servers: ['NA', 'BR', 'LAN', 'LAS'],
    },
    {
      region: 'Asia',
      servers: ['KR', 'JP'],
    },
    {
      region: 'Other',
      servers: ['OCE', 'PH2', 'SG2', 'TH2', 'TW2', 'VN2'],
    },
  ]

  return {
    connectLOL,
    connectVALORANT,
    connectDOTA,
    connectFORTNITE,
    LOLServers,
  }
}

export default useConnectGame
