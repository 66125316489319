import { Input } from '@heroui/react'
import { Controller } from 'react-hook-form'

// this is done like that cuz it hs an issue with RHF
export const FormInput = ({ name, ...props }) => {
  return (
    <Controller
      name={name}
      control={props.control}
      render={({ field, fieldState, formState }) => {
        return (
          <Input
            {...props}
            isInvalid={!!formState.errors?.[name]?.message}
            errorMessage={formState.errors?.[name]?.message?.toString()}
            value={field.value}
            onChange={field.onChange}
            classNames={inputClasses}
            labelPlacement={props.labelPlacement ?? 'outside'}
            variant={props.variant ?? 'bordered'}
          />
        )
      }}
    ></Controller>
  )
}

export const inputClasses = {
  label: 'font-display text-jacarta-700 text-sm dark:text-white',
  input: [
    'min-w-[120px]',
    // 'text-black/90 dark:text-white/90',
    // 'placeholder:text-default-700/50 dark:placeholder:text-white/60',
    'focus:ring-transparent dark:focus:ring-transparent border-none px-0 bg-transparent',
  ],
  // innerWrapper: 'bg-transparent',
  inputWrapper: [
    //   'shadow-xl',
    //   'bg-default-200/50',
    //   'dark:bg-default/60',
    //   'backdrop-blur-xl',
    //   'backdrop-saturate-200',
    //   'hover:bg-default-200/70',
    //   'dark:hover:bg-default/70',
    'group-data-[focus=true]:border-accent',
    //   'dark:group-data-[focus=true]:bg-default/60',
    //   '!cursor-text',
  ],
}
