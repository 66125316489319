import React from 'react'
import nodeAPI from '@/lib/nodeapi'
import { useQuery } from '@tanstack/react-query'

interface Props {
  searchQuery: string
}

function useSeachUsers({ searchQuery }: Props) {
  const query = useQuery({
    queryKey: ['search-users', { searchQuery }],
    queryFn: async () => {
      const response = await nodeAPI(
        'GET',
        null,
        `profile/Gamerg_publicProfileSearchEngine?searchQuery=${searchQuery}`,
        {
          searchQuery,
        },
      )

      return response
    },

    enabled: searchQuery.length >= 3,
  })

  return query
}

export default useSeachUsers
