import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Tooltip } from '@heroui/react'
import dayjs from 'dayjs'
import ar from 'dayjs/locale/ar'
import en from 'dayjs/locale/en'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

const TimeZoneField = ({ dateTime, showYear = false }) => {
  const router = useRouter()
  const [userTimezone, setUserTimezone] = useState('')
  const [convertedDateTime, setConvertedDateTime] = useState('')

  useEffect(() => {
    dayjs.locale(router.locale == 'ar-SA' ? ar : en)
    // Detect user's timezone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    setUserTimezone(tz)

    // Convert the provided dateTime from Asia/Amman to the user's timezone
    const originalDateTime = dayjs(dateTime).tz('Asia/Amman') // assuming dateTime is already in Asia/Amman timezone

    const converted = originalDateTime.tz(tz)

    const formatString = showYear
      ? 'ddd, MMM D, YYYY, h:mmA'
      : 'ddd, MMM D, h:mmA'

    setConvertedDateTime(converted.format(formatString))
  }, [dateTime, showYear, router])

  return (
    <div>
      <Tooltip
        className="rounded bg-accent p-2 text-white"
        content={userTimezone}
        placement="top"
      >
        <span>{convertedDateTime}</span>
      </Tooltip>
    </div>
  )
}

export default TimeZoneField
