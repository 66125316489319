import Link from 'next/link'
import { Spinner } from '@heroui/react'
import useTopEarners from '@gg/api/query/useTopEarners'
import { useTranslation } from 'react-i18next'
import UserAvatar from '../dashboard/live/userAvatar'
import { formatNumber } from '@gg/shared/utils/helpers'

const TopWeeklyEarners = () => {
  const { t } = useTranslation()

  const { data: users, isLoading } = useTopEarners()

  return (
    <>
      <h2 className="font-display my-2 px-5 py-1 text-center text-lg">
        {t(`Top GamerG Winners`)}
      </h2>
      <div className="flex w-full flex-col items-center gap-2">
        {isLoading && <Spinner size="sm" className="my-10" />}
        {!isLoading && users?.length == 0 && (
          <p className="my-10 px-5 text-center">
            {t('No earners yet this week')}
          </p>
        )}

        {users?.map((u) => (
          <div
            key={u.username}
            className="border-jacarta-200 dark:border-jacarta-600 flex w-full items-center justify-between gap-2 rounded-md border p-1"
          >
            <Link
              className="flex w-max items-center gap-2"
              href={`/u/${u?.username}`}
            >
              <UserAvatar user={u} />
            </Link>

            <span className="text-green-success font-display me-3 text-lg">
              {formatNumber(u.total, {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
              })}
            </span>
          </div>
        ))}
      </div>
    </>
  )
}

export default TopWeeklyEarners
