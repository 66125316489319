import { Button, cn, Link } from '@heroui/react'
import { t } from 'i18next'
import { SiDiscord } from 'react-icons/si'
import { primaryButton } from '@/theme/nextUI'

export default function DiscordButton({
  text = 'Join the Community',
  isIconOnly = false,
  className = '',
  type,
  size = 'md',
}: {
  text?: string
  isIconOnly?: boolean
  className?: string
  type?: 'connect' | 'join'
  size?: 'sm' | 'md' | 'lg'
}) {
  return (
    <Button
      size={size}
      className={cn(primaryButton, 'rounded-full bg-[#5865f2]', className)}
      as={Link}
      target="_blank"
      href="https://discord.gg/uSJkN95JxW"
      startContent={<SiDiscord size={18} />}
      isIconOnly={isIconOnly}
    >
      {!isIconOnly && t(text)}
    </Button>
  )
}
