import useUserCountryAndDate from '../useUserCountryAndDate'

const allowedCountries = ['EG', 'SA', 'KW']

const tamatemPaymentMethods = {
  EG: [
    {
      name: 'Fawry cash',
      image: {
        light: '/images/tamatem-methods/fawry.png',
        dark: '/images/tamatem-methods/fawry.png',
      },
    },
    {
      name: 'Vodafone wallet',
      image: {
        light: '/images/tamatem-methods/vf-light.png',
        dark: '/images/tamatem-methods/vf-dark.png',
      },
    },
    {
      name: 'Orange cash',
      image: {
        light: '/images/tamatem-methods/orange-light.png',
        dark: '/images/tamatem-methods/orange-dark.png',
      },
    },
  ],
  SA: [
    {
      name: 'STC Pay',
      image: {
        light: '/images/tamatem-methods/stc-light.png',
        dark: '/images/tamatem-methods/stc-dark.png',
      },
    },
    {
      name: 'Mada',
      image: {
        light: '/images/tamatem-methods/mada-light.png',
        dark: '/images/tamatem-methods/mada-dark.png',
      },
    },
    {
      name: 'Sadad',
      image: {
        light: '/images/tamatem-methods/sadad-light.png',
        dark: '/images/tamatem-methods/sadad-dark.png',
      },
    },
  ],
  KW: [
    {
      name: 'KNET',
      image: {
        light: '/images/tamatem-methods/knet.png',
        dark: '/images/tamatem-methods/knet.png',
      },
    },
  ],
}

/**
 * 1- we need to check if the user is from the allowed countries
 * 2- if the user is from the allowed countries we need to fetch the payment methods for this country
 * 3- view allowed countries payment methods
 * 4- do payment
 */

const useTamatemPaymentMethods = () => {
  // Get user country
  const { countryAndDate } = useUserCountryAndDate()

  if (allowedCountries.includes(countryAndDate?.whichCountry))
    return {
      tamatemPaymentMethods:
        tamatemPaymentMethods[countryAndDate?.whichCountry],
    }

  return { tamatemPaymentMethods: [] }
}

export default useTamatemPaymentMethods
