import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface State {
  tournamentId?: number
  setTournamentId: (tournamentId: State['tournamentId']) => void
  bracketId?: number
  setBracketId: (bracketId: State['bracketId']) => void
}

export const useLiveStore = create<State>()(
  persist(
    (set) => ({
      tournamentId: undefined,
      setTournamentId: (tournamentId) => set(() => ({ tournamentId })),
      bracketId: undefined,
      setBracketId: (bracketId) => set(() => ({ bracketId })),
    }),
    {
      name: 'live-store',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
)
