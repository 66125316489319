import { create } from 'zustand'

interface ControlPanelState {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

export const useControlPanelStore = create<ControlPanelState>()((set) => ({
  isOpen: false,
  setIsOpen: (open) => set(() => ({ isOpen: open })),
}))
