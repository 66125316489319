import { useRouter } from 'next/router'
import { modalStyle } from '@/theme/nextUI'
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  SharedSelection,
} from '@heroui/react'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import {
  setBonusModal,
  useAppDispatch,
  useAppSelector,
} from '@/redux/counterSlice'
import { BiLockAlt, BiX } from 'react-icons/bi'
import GAMES from '@gg/shared/constants/games'
import { formatNumber } from '@gg/shared/utils/helpers'
import useBonusEligible from '@gg/api/query/useBonusEligible'
import { useEffect, useState } from 'react'
import useConnectGame from '@/hooks/useConnectGame'
import { SelectLOLServer } from '../accountSettings/ConnectGames'
import { primaryButton } from '@/theme/nextUI'
import { motion, AnimatePresence } from 'framer-motion'
import { BrawlForm } from './auth/connectBrawl'
import useUpdateBalance from '@/hooks/useUpdateBalance'
import { useGameStore } from '@/store/useGameStore'
import { useControlPanelStore } from '@/store/useControlPanelStore'

const BonusModal = () => {
  const { t } = useTranslation()
  const { pathname } = useRouter()
  const dispatch = useAppDispatch()
  const { bonusModalIsOpen } = useAppSelector((state) => state.counter)
  const [isLOLserverShown, setIsLOLserverShown] = useState(false)
  const [isBrawlShown, setIsBrawlShown] = useState(false)
  const [successfulConnection, setSuccessfulConnection] = useState(false)

  const { setGameId } = useGameStore()
  const { setIsOpen } = useControlPanelStore()
  const { data, refetch } = useBonusEligible()

  const { connectLOL, connectVALORANT, connectDOTA, connectFORTNITE } =
    useConnectGame({ origin: 'bonus' })

  const showLOLServer = () => setIsLOLserverShown(true)
  const connectBrawl = () => setIsBrawlShown(true)

  const gamesWithConnect = GAMES.filter((game) => game.isActive).map((game) => {
    let connect: (() => void) | undefined

    if (+game.value === 1) connect = showLOLServer
    if (+game.value === 2) connect = connectVALORANT
    if (+game.value === 3) connect = connectDOTA
    if (+game.value === 4) connect = connectBrawl
    if (+game.value === 5) connect = connectFORTNITE

    return {
      ...game,
      connect: () => {
        setGameId(game.value)
        cookies.remove('gameConnected')
        cookies.remove('gameConnectedError')
        connect?.()
      },
    }
  })

  const handleModalOpening = () => {
    if (!data?.bonusEligible) {
      dispatch(setBonusModal(false))
      return
    }

    const gameConnected = cookies.get('gameConnected')
    setSuccessfulConnection(gameConnected == 'success')

    const autoOpenDisabledPaths = [
      '/riot/callback',
      '/epic/callback/game',
      '/steam/callback/dota',
    ]

    const isDelayed = autoOpenDisabledPaths.includes(pathname)
    if (isDelayed) return

    setIsOpen(false)
    dispatch(setBonusModal(true))
  }

  useEffect(() => {
    handleModalOpening()
  }, [data?.bonusEligible, pathname, isBrawlShown])

  const [lolServer, setLOLServer] = useState<string | undefined>(undefined)
  const selectLOLServer = ({ currentKey }: SharedSelection) => {
    setLOLServer(currentKey)
    if (currentKey) cookies.set('lolserver', currentKey)
    else cookies.remove('lolserver')
  }

  const onClose = (skip = false) => {
    if (skip) skipFor()
    else refetch()

    cookies.remove('gameConnected')
    cookies.remove('gameConnectedError')

    dispatch(setBonusModal(false))
  }

  const skipFor = (ms = 30000) => {
    let timeout = setTimeout(() => {
      handleModalOpening()
      clearTimeout(timeout)
    }, ms)
  }

  return (
    <Modal
      isOpen={bonusModalIsOpen}
      size="md"
      classNames={modalStyle}
      hideCloseButton
    >
      <ModalContent>
        <ModalBody className="font-display flex flex-col items-center gap-2">
          <div
            className="flex flex-col items-center justify-center rounded-lg p-2 text-white"
            style={{
              backgroundImage: `url(/images/bonus/bonus_bg.jpg)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: '100%',
            }}
          >
            <div className="flex items-center gap-2 text-white">
              <h2 className="text-4xl font-black">{t('GET')} 20</h2>
              <Image
                src="/images/gg-credit/GG credits.png"
                className="h-8 w-8"
              />
            </div>

            <p className="text-sm">
              {t(`Instantly after linking your game account`)}
            </p>
          </div>

          <div className="relative flex flex-col items-center gap-2 pb-1 pt-2 *:text-center">
            <ConnectStatus onClose={onClose} />

            {!successfulConnection && (
              <>
                <p>{t(`Start winning by choosing your favourite game`)}</p>

                <div className="flex-center my-2 flex-wrap gap-2 sm:grid sm:grid-cols-2 sm:gap-3">
                  {gamesWithConnect.map((game) => (
                    <Button
                      key={game.value}
                      className="h-12 justify-start rounded-full bg-jacarta-800 p-0 pe-5 text-white dark:bg-jacarta-600"
                      onPress={game.connect}
                    >
                      <Image
                        src={`/images/gamelogo/new/${game.image}`}
                        className="h-12 w-12 shrink-0 rounded-full"
                        removeWrapper
                        alt={game.label}
                      />
                      <div className="flex w-full max-w-sm flex-col items-start gap-0.5">
                        <h2 className="truncate font-bold">{t(game.label)}</h2>
                        <p className="text-xs">
                          {formatNumber(data?.userGameCount[+game.value]! || 0)}{' '}
                          {t('Players')}
                        </p>
                      </div>
                    </Button>
                  ))}
                </div>

                <Button
                  className="text-xs text-jacarta-400"
                  size="sm"
                  variant="ghost"
                  onPress={() => onClose(true)}
                >
                  {t('Skip')}
                </Button>

                <p className="flex items-center gap-1 text-xs">
                  <BiLockAlt />
                  {t('All connections are secure, your data is protected')}
                </p>

                <AnimatePresence>
                  {(isLOLserverShown || isBrawlShown) && (
                    <motion.div
                      className="font-body absolute inset-0 grid rounded-md bg-white/90 p-3 backdrop-blur-sm dark:bg-jacarta-900/70"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <Button
                        isIconOnly
                        size="sm"
                        onPress={() => {
                          setIsLOLserverShown(false)
                          setIsBrawlShown(false)
                        }}
                        className="absolute right-2 top-2"
                        radius="full"
                      >
                        <BiX size={20} />
                      </Button>

                      <motion.div
                        className="mx-auto flex w-full max-w-xs flex-col items-center justify-center gap-2"
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                      >
                        {isLOLserverShown && (
                          <>
                            <h2 className="font-display mb-2 text-xl">
                              {t('Select LOL server')}
                            </h2>
                            <SelectLOLServer
                              selectedServer={lolServer}
                              onSelect={selectLOLServer}
                            />

                            <Button
                              className={primaryButton}
                              onPress={connectLOL}
                              isDisabled={!lolServer}
                              radius="full"
                            >
                              {t('Connect')}
                            </Button>
                          </>
                        )}

                        {isBrawlShown && (
                          <>
                            <h2 className="font-display mb-2 text-xl">
                              {t('Connect Brawlstars')}
                            </h2>
                            <BrawlForm
                              onSuccess={() => {
                                setIsBrawlShown(false)
                                cookies.set('gameConnected', 'success')
                              }}
                              onClose={() => setIsBrawlShown(false)}
                              compact
                            />
                          </>
                        )}
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const ConnectStatus = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const gameConnected = cookies.get('gameConnected')
  const gameConnectedError = cookies.get('gameConnectedError')
  const { updateBalance } = useUpdateBalance()

  useEffect(() => {
    if (gameConnected == 'success') updateBalance()
  }, [gameConnected])

  const playNow = () => {
    onClose()
    router.push('/dashboard')
  }

  if (!gameConnected) return null

  if (gameConnected == 'success') {
    return (
      <div className="flex w-full flex-col items-center justify-center gap-2 rounded-lg border border-accent bg-accent/50 p-2">
        <h2 className="font-display text-sm">{t('Game Connected')} ✅</h2>
        <span className="font-display rounded-full px-4">
          {t(
            'You have recieved 20 GG credits now Start playing your favourite game',
          )}
        </span>

        <Button
          className={primaryButton}
          radius="full"
          size="sm"
          onPress={playNow}
        >
          {t('Play Now')}
        </Button>
      </div>
    )
  }

  return (
    <div className="flex w-full flex-col items-center justify-center gap-2 rounded-lg border border-red-base bg-red-500/50 p-2">
      <span className="font-display rounded-full px-4">
        {gameConnectedError ? gameConnectedError : t('An error occurred')}
      </span>
    </div>
  )
}

export default BonusModal
