import { useQuery } from '@tanstack/react-query'
import { useCurrencyStore } from '../store/useCurrencyStore'
import API from '@gg/api'

interface Currency {
  currency_code: string
  currency_name: string
  currency_name_ar: string
  rate: number
  digit: number
}

const useCurrencyRate = () => {
  const currCurrency = useCurrencyStore((state) => state.currency)
  const setLatestRate = useCurrencyStore((state) => state.setLatestRate)

  const { data, isLoading } = useQuery({
    queryKey: ['getAllCurrencies'],
    queryFn: async () => {
      const { results } = await API.get<{ results: Currency[] }>(
        'extra/getAllCurrencies',
        { requiresAuth: false },
      )

      return results
    },
    staleTime: 1000 * 60 * 60,
  })

  const currency = data?.find(
    ({ currency_code }) => currency_code == currCurrency,
  )
  if (currency) setLatestRate(currency.rate, currency.digit)

  return { data, isLoading }
}

export default useCurrencyRate
