import CurrencyField from '@/components/CurrencyField'
import { t } from 'i18next'
import { CardTooltip } from './CardTooltip'
import { formatNumber } from '@gg/shared/utils/helpers'
export const PrizeCard = ({ balance }: { balance: number }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="relative flex items-center justify-between">
        <p className={`text-sm font-semibold text-accent`}>{t('Prizes')}</p>
        <CardTooltip
          text={t('Anything you win from Tournaments will be earned here')}
        />
      </div>

      <div
        className={`relative w-full rounded-md border border-accent px-2 py-2 text-center text-accent`}
      >
        <p className={`text-md text-accent} font-semibold`}>
          {formatNumber(balance ?? 0, {
            style: 'currency',
            currency: 'USD',
          })}
        </p>
      </div>

      <CurrencyField amount={balance ?? 0} />
    </div>
  )
}
