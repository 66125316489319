import { useQuery } from '@tanstack/react-query'
import API from '../api'
type Country = {
  id: 2
  country_code: string
  country_enName: string
  country_arName: string
  country_arNationality: string
  allowed: 1 | 0
}

const useCountries = () =>
  useQuery({
    queryKey: ['Countries'],
    queryFn: async () => {
      const { results } = await API.get<{ results: Country[] }>(
        'extra/getAllAllowedCountries',
      )

      return results
    },
  })

export default useCountries
