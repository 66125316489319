import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import { getToken } from './getToken'
import { getBaseUrl } from './getBaseUrl'

const baseURL = getBaseUrl()

const Axios = axios.create({
  baseURL,
  validateStatus(status) {
    return status < 501
  },
})

Axios.interceptors.request.use(async (config: InterceptorConfig) => {
  const token = await getToken()
  const requiresAuth = config.requiresAuth ?? true

  if (requiresAuth && !token) {
    console.log(
      `Aborting request: %c${config.url}`,
      'color: red; text-decoration: underline;',
    )

    return Promise.reject({
      config,
      response: {
        status: 401,
        statusText: 'Unauthorized - Authentication required',
      },
    })
  }

  config.headers.Secure = token
  return config
})

const API = {
  get: async <T>(path: string, config?: Config): Promise<T> => {
    const { data } = await Axios.get<T>(path, config)
    return data
  },
  post: async <T>(
    path: string,
    body?: Record<string, any>,
    config?: Config,
  ): Promise<T> => {
    const { data } = await Axios.post<T>(path, body, config)
    return data
  },
  put: async <T>(
    path: string,
    body?: Record<string, any>,
    config?: Config,
  ): Promise<T> => {
    const { data } = await Axios.put<T>(path, body, config)
    return data
  },
  delete: async <T>(path: string, config?: Config): Promise<T> => {
    const { data } = await Axios.delete<T>(path, config)
    return data
  },
}

export default API

interface Config extends AxiosRequestConfig {
  requiresAuth?: boolean
}

interface InterceptorConfig extends InternalAxiosRequestConfig {
  requiresAuth?: boolean
}
