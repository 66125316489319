import { useQuery } from '@tanstack/react-query'
import API from '../../api'

interface UserGame {
  status: boolean
  name: string
}

const useUserGame = (game: number) =>
  useQuery({
    queryKey: ['UserGame', game],
    queryFn: async () =>
      await API.post<UserGame>('play/getUserGame', { game_id: game }),
    enabled: !!game,
  })

export default useUserGame
