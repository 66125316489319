import SocialMediaIcons from '@/components/socialMediaIcons'
import { Button, Card, CardBody, CardHeader, cn } from '@heroui/react'
import { t } from 'i18next'
import { useDispatch } from 'react-redux'
import { setCreditModal } from '@/redux/counterSlice'
import { useBalanceStore } from '@/store/useBalance'
import { useControlPanelStore } from '@/store/useControlPanelStore'
import { GemsCard } from '../layout/cards/GemsCard'
import { GGCard } from '../layout/cards/GGCard'
import { PrizeCard } from '../layout/cards/PrizeCard'
import { SidebarNavlist } from '../layout/cards/SidebarNavlist'
import CurrencySwitcher from './CurrencySwitcher'
import LangSwitcher from './LangSwitcher'
import { NavMenuMainActoins } from './NavMenuMainActoins'
import UserInofCardSM from '../userProfile/cards/UserInofCardSM'
import useUserProfile from '@gg/api/query/user/useUserProfile'

export default function CtrMenu({ isAuthenticated = false }) {
  const dispatch = useDispatch()
  const { balance } = useBalanceStore()
  const { setIsOpen } = useControlPanelStore()
  const { data: userProfileData } = useUserProfile()

  return (
    <Card
      shadow="none"
      className={cn(
        'w-full border-none bg-transparent',
        'max-h-[80vh] overflow-y-auto md:max-h-full',
      )}
    >
      <CardHeader className="rounded-large flex-col justify-between gap-2 overflow-hidden p-0 xl:hidden">
        {isAuthenticated ? <UserInofCardSM user={userProfileData} /> : null}
      </CardHeader>
      <CardBody className="scrollbar-hide gap-4 px-4">
        <div className="flex-center w-full gap-4">
          <CurrencySwitcher inControlMenu />
          <LangSwitcher inControlMenu />
        </div>
        {isAuthenticated ? (
          <>
            <div className="flex gap-3">
              <GemsCard balance={+balance?.depositBalance?.toFixed(2)} />
              <PrizeCard balance={+balance?.prizeUserBalance?.toFixed(2)} />
            </div>
            <div className="flex gap-3">
              <GGCard balance={balance?.creditBalance} type="menu" />
              <Button
                className="font-display max-w-[48%] flex-1 self-end rounded-md bg-GG-blue text-sm text-white"
                onPress={() => {
                  dispatch(setCreditModal(true))
                  setIsOpen(false)
                }}
              >
                {t('Claim')}
              </Button>
            </div>
            {/* <Button
              size="md"
              radius="full"
              className="bg-red hover:bg-accent-dark w-full text-lg font-semibold text-white transition-all leading-none"
              onClick={() => {
                trackConnectGame();
                setIsOpen(false);
                router.push('/dashboard/account-settings?t=connect');
              }}
            >
              {t('Connect Games')}
            </Button> */}
          </>
        ) : null}
        <NavMenuMainActoins isAuthenticated={isAuthenticated} type={'navbar'} />
        <div
          className={`border-t-1 flex flex-col items-start gap-1 border-jacarta-300 pt-3 xl:items-center ${isAuthenticated ? 'xl:pt-0' : ''}`}
        >
          <SidebarNavlist />
          <SocialMediaIcons className="mt-3 justify-center" />
        </div>
      </CardBody>
      {/* <CardFooter
        className={`border-t-1 border-jacarta-300 flex items-start xl:items-center flex-col gap-1 ${isAuthenticated ? 'xl:pt-0' : ''}`}
      >
        <SidebarNavlist />
        <SocialMediaIcons className="mt-3 justify-center" />
      </CardFooter> */}
    </Card>
  )
}

// function BalanceCard({ balance, title, notUSD, withGem }) {
//   return (
//     <div className="flex items-center gap-2 flex-col">
//       <p
//         className={`text-md font-bold ${withGem ? 'text-purple' : 'text-accent'}`}
//       >
//         {title}
//       </p>
//       <div
//         className={`rounded-full text-center  py-1 border ${
//           withGem ? 'border-purple px-6' : 'border-accent px-4'
//         }`}
//       >
//         <p className={`text-lg font-bold ${withGem ? '' : 'text-accent'}`}>
//           {withGem ? (
//             <Gem text={balance?.toFixed(2)} />
//           ) : (
//             `${balance?.toFixed(2)} ${t('USD')}`
//           )}
//         </p>
//       </div>
//       <CurrencyField amount={balance} />
//     </div>
//   );
// }
