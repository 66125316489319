import { ReactNode } from 'react'
import Link from 'next/link'
import { getLetterColor } from '@/lib/helpers'
import { Avatar, cn } from '@heroui/react'
import RankTooltip from '@/components/RankTooltip'

interface UserAvatarI {
  user: {
    username: string
    profile_pic: string
    userRank: {
      name: string
      icon: string
    }
  }
  belowUsername?: ReactNode
}

const UserAvatar = ({ user, belowUsername }: UserAvatarI) => {
  return (
    <Link
      className="flex w-max items-center gap-2"
      href={`/u/${user?.username}`}
    >
      <UserImage user={user} hasBorder />

      <div className="flex flex-col items-start justify-center">
        <div className="flex items-center gap-1">
          <span className="font-display text-medium">{user?.username}</span>

          <RankTooltip userRank={user?.userRank} />
        </div>
        {belowUsername}
      </div>
    </Link>
  )
}

export default UserAvatar

export const UserImage = ({
  user,
  raduis,
  showShadow,
  hasBorder,
  className,
}: {
  user: {
    username: string
    profile_pic: string
  }
  raduis?: 'sm' | 'md' | 'lg' | 'none' | 'full'
  showShadow?: boolean
  hasBorder?: boolean
  className?: string
}) => {
  return (
    <Avatar
      src={user?.profile_pic ?? '/images/gamelogo/small/gamerg-white.png'}
      style={{
        backgroundColor: user?.profile_pic
          ? ''
          : getLetterColor(user?.username?.[0] ?? ''),
      }}
      radius={raduis ?? 'sm'}
      alt="user image"
      className={cn(
        'h-12 w-12',
        !user?.profile_pic && 'p-1.5',
        showShadow && 'shadow-base',
        hasBorder && 'border-jacarta-100 dark:border-jacarta-600 border-2',
        className,
      )}
    />
  )
}
