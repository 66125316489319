import { useQuery } from '@tanstack/react-query'
import API from '../../api'
import { AccountDetailResponse } from '@gg/shared/types'

const useUserProfile = (key?: boolean) =>
  useQuery({
    queryKey: ['getUserProfile', key],
    queryFn: async () => {
      const { results, displayName } = await API.post<{
        results: AccountDetailResponse
        displayName: string
      }>('profile/getUserProfile')

      return { ...results, displayName }
    },
    staleTime: Infinity,
  })

export default useUserProfile
