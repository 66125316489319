import { setCreditModal, useAppDispatch } from '@/redux/counterSlice'
import { useControlPanelStore } from '@/store/useControlPanelStore'
import { Image } from '@heroui/react'
import { t } from 'i18next'
import { CardTooltip } from './CardTooltip'
import { formatNumber } from '@gg/shared/utils/helpers'

interface Props {
  balance: number
  type?: 'menu' | 'sidebar'
}
export const GGCard = ({ balance, type = 'sidebar' }: Props) => {
  const dispatch = useAppDispatch()

  const { setIsOpen } = useControlPanelStore()

  return (
    <div className="flex flex-1 flex-col gap-2">
      {type === 'sidebar' && (
        <div className="flex items-center justify-between">
          <p
            className={`text-GG-blue dark:text-GG-blue-light text-sm font-semibold`}
          >
            {t('GG Credits')}
          </p>
          <CardTooltip
            text={t(
              `GG Points: You receive GG's Points daily for free. Use them to join tournaments or purchase gift cards!`,
            )}
          />
        </div>
      )}
      <div
        className={`text-GG-blue dark:text-GG-blue-light border-GG-blue dark:border-GG-blue-light relative w-full cursor-pointer rounded-md border px-4 py-2 text-center`}
        onClick={() => {
          dispatch(setCreditModal(true))
          setIsOpen(false)
        }}
      >
        <p
          className={`text-md text-GG-blue dark:text-GG-blue-light me-2 font-semibold`}
        >
          {formatNumber(balance ?? 0)}{' '}
        </p>
        <div className="absolute end-1 top-1/2 -translate-y-1/2 self-end">
          <Image
            disableSkeleton
            src="/images/gg-credit/GG credits.png"
            className="h-5 w-5"
            alt="gg credit"
          />
        </div>
      </div>
    </div>
  )
}
