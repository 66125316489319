import { FC } from 'react'
import useUserProfile from '@gg/api/query/user/useUserProfile'
import {
  setupAccountModalShow,
  useAppDispatch,
  useAppSelector,
} from '@/redux/counterSlice'
import { Button, ButtonProps, cn } from '@heroui/react'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'react-i18next'
import { primaryButton } from '@/theme/nextUI'

const VerifyAccount: FC<ButtonProps> = ({ className, ...rest }) => {
  const { status } = useSession()
  const dispatch = useAppDispatch()
  const { checkAccountSet } = useAppSelector((state) => state.counter)
  const { data: userProfile, isLoading } = useUserProfile(checkAccountSet)
  const { t } = useTranslation()

  if (status != 'authenticated' || isLoading || userProfile?.username)
    return null

  return (
    <Button
      className={cn(primaryButton, className)}
      onPress={() => dispatch(setupAccountModalShow())}
      {...rest}
    >
      {t('Verify your account')}
    </Button>
  )
}

export default VerifyAccount
