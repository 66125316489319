import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from '@heroui/react'
import { t } from 'i18next'
import { useSession } from 'next-auth/react'
import useHasLiveTours from '@gg/api/query/play/useHasLiveTours'
import useTracker, { TrakerTypes } from '@/hooks/useTraker'
import Searchbar from '../layout/cards/Searchbar'
import CurrencySwitcher from './CurrencySwitcher'
import GoToGPartnerButton from './GoToGPartnerButton'
import LangSwitcher from './LangSwitcher'
import LoginButtonWithMenu from './LoginButtonWithMenu'
import Logo from './Logo'
import Notifications from './Notifications'
import SearchButton from './SearchButton'
import ToggleThemeButton from './ToggleThemeButton'
import UnAuthMobileMenu from './UnAuthMobileMenu'
import useAccountSet from '@gg/api/query/useAccountSet'
import { useAppSelector } from '@/redux/counterSlice'

export default function MainNavbar() {
  const router = useRouter()
  const { asPath } = router
  const { status } = useSession()
  const { data: userDetails } = useAccountSet()
  const isLoggedIn = status === 'authenticated'
  const isAuthenticated = isLoggedIn && !userDetails?.banned
  const { liveKey } = useAppSelector((state) => state.counter)
  const { hasLive } = useHasLiveTours(liveKey)
  const trackTournamentPage = useTracker({
    key: TrakerTypes.TournamentPageVisited,
  })

  return (
    <Navbar
      maxWidth="full"
      className="dark:bg-jacarta-900 z-50 bg-[#f7f5ff] shadow-md dark:bg-transparent"
      classNames={{
        item: [
          'cursor-pointer font-semibold items-center hover:text-accent transition-all',
        ],
      }}
    >
      <NavbarBrand className="grow-0 basis-auto cursor-pointer">
        <Logo />
      </NavbarBrand>
      {isLoggedIn ? null : (
        <NavbarContent
          className="hidden gap-2 lg:flex xl:gap-4"
          justify="start"
        >
          <NavbarItem className={`${asPath === '/' ? 'text-accent' : ''}`}>
            <Link href="/">{t('Home')}</Link>
          </NavbarItem>

          <NavbarItem
            className={`border-accent-dark py-.5 rounded-full border-2 px-3 ${asPath.includes('/dashboard') || asPath === '/tournaments' ? 'text-accent' : ''} ${hasLive ? 'animate-border-color' : ''} `}
            onClick={trackTournamentPage}
          >
            <Link
              href={
                isAuthenticated
                  ? `/dashboard${hasLive ? '?t=live' : ''}`
                  : '/tournaments'
              }
            >
              {t('Tournaments')}
            </Link>
          </NavbarItem>

          <NavbarItem
            className={`${asPath === '/leaderboard' ? 'text-accent' : ''}`}
          >
            <Link href="/leaderboard">{t('Leaderboard')}</Link>
          </NavbarItem>
          <NavbarItem
            className={`${asPath === '/leaderboard' ? 'text-accent' : ''}`}
          >
            <Link href="/contact">{t('Contact_Us')}</Link>
          </NavbarItem>
        </NavbarContent>
      )}

      <NavbarContent className="flex items-center gap-2 xl:gap-4" justify="end">
        <NavbarItem className="hover:text-current xl:hidden">
          <SearchButton />
        </NavbarItem>
        <NavbarItem className="hidden hover:text-current xl:block">
          <Searchbar />
        </NavbarItem>
        {isAuthenticated && (
          <NavbarItem>
            <Notifications />
          </NavbarItem>
        )}
        <NavbarItem
          className={`${isAuthenticated ? 'order-1' : 'lg:order-none'} `}
        >
          <LoginButtonWithMenu />
        </NavbarItem>
        {!isAuthenticated && (
          <NavbarItem className="order-1">
            <UnAuthMobileMenu />
          </NavbarItem>
        )}

        {isAuthenticated && (
          <NavbarItem>
            <GoToGPartnerButton />
          </NavbarItem>
        )}
        <NavbarItem className="hidden lg:block">
          <CurrencySwitcher />
        </NavbarItem>
        <NavbarItem className="hidden lg:block">
          <LangSwitcher />
        </NavbarItem>
        <NavbarItem>
          <ToggleThemeButton />
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  )
}

const isHelpCenterPath = (path: string) => {
  return path === '/faq' || path === '/contact'
}
const isLearnMorePath = (path: string) => {
  const paths = [
    '/how-to-win',
    '/affiliate-program',
    '/matchmaking-rating',
    '/gamerg-requests',
  ]

  return paths.includes(path)
}
