import { useRouter } from 'next/router'
import useAnnouncements from '@gg/api/query/useAnnouncements'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

const Announcements = () => {
  const router = useRouter()
  const { data } = useAnnouncements()
  const { t } = useTranslation()

  if (!data?.status) return

  return (
    <motion.div
      className="font-display flex w-full flex-col gap-1 rounded-md border-2 border-[#ffe69c] bg-[#fff3cd] p-2 text-[#664d03]"
      initial={{ opacity: 0, y: 200 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <h3>{t(`Announcements`)}</h3>
      <p className="text-sm">
        {router.locale === 'en-US'
          ? data?.announcement_en
          : data?.announcement_ar}
      </p>
    </motion.div>
  )
}

export default Announcements
