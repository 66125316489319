import React, { ChangeEvent, useCallback, useState } from 'react'
import debounce from 'lodash.debounce'

const useDebounceSearch = () => {
  const [searchKey, setSearchKey] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchHandler = useCallback(
    debounce((value) => setSearchKey(value), 500),
    [],
  )
  function handleSearchChange(evt: ChangeEvent<HTMLInputElement>) {
    const value = evt.currentTarget.value
    searchHandler(value)
  }

  return { searchKey, setSearchKey, handleSearchChange }
}

export default useDebounceSearch
