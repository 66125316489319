import { setCreditModal, useAppDispatch } from '@/redux/counterSlice'
import { Button } from '@heroui/react'
import { useBalanceStore } from '@/store/useBalance'
import { t } from 'i18next'
import cookies from 'js-cookie'
import DiscordButton from '../../navbar/DiscordButton'

import { NavMenuMainActoins } from '@/components/navbar/NavMenuMainActoins'
import UserInofCardSM from '@/components/userProfile/cards/UserInofCardSM'
import { useRouter } from 'next/router'
import { GemsCard } from '../cards/GemsCard'
import { GGCard } from '../cards/GGCard'
import { PrizeCard } from '../cards/PrizeCard'
import { SidebarNavlist } from '../cards/SidebarNavlist'
import useUserProfile from '@gg/api/query/user/useUserProfile'

function Sidebar() {
  const { balance } = useBalanceStore()
  const currencyNotUSD =
    cookies.get('currency') && cookies.get('currency') !== 'USD'
  const { data: userProfileData } = useUserProfile()
  const dispatch = useAppDispatch()
  const { asPath } = useRouter()

  const pathsToHideIn = ['/admonitor']
  if (pathsToHideIn.some((path) => asPath.includes(path))) {
    return <></>
  }

  return (
    <aside className="sticky-0 border-e-1 top-[64px] z-40 hidden h-[calc(100vh-64px)] w-72 flex-col border-jacarta-100 bg-[#f7f5ff] shadow-lg xl:sticky xl:flex dark:border-jacarta-700 dark:bg-jacarta-800">
      <UserInofCardSM user={userProfileData} />
      {/* <UserCard userProfileData={userProfileData} /> */}
      <div className="flex flex-1 flex-col px-5 py-5">
        <div className="flex-1">
          <div className="menu-step-1">
            <div className="my-3 flex gap-5">
              <GemsCard balance={balance?.depositBalance?.toFixed(2)} />
              <PrizeCard
                balance={balance?.prizeUserBalance?.toFixed(2)}
                notUSD={currencyNotUSD}
              />
            </div>

            <div className="mb-5 flex gap-5">
              <GGCard balance={balance?.creditBalance} />
              <Button
                className="font-display max-w-[110px] flex-1 self-end rounded-md bg-GG-blue text-sm text-white"
                onClick={() => dispatch(setCreditModal(true))}
              >
                {t('Claim')}
              </Button>
            </div>

            <NavMenuMainActoins isAuthenticated={true} user={userProfileData} />
          </div>
        </div>
        {/* <DiscordButton /> */}
        <div className="mt-3 ps-4">
          <SidebarNavlist />
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
