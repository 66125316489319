import { getBaseUrl } from '@gg/api/getBaseUrl'

export default async function nodeAPI(
  method,
  secure,
  query,
  variables = {},
  multipart = false,
) {
  const API_URL = getBaseUrl()

  const headers = { secure }

  if (multipart) {
    delete headers['Content-Type']
  } else {
    headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }

  try {
    const res = await fetch(API_URL + query, {
      method: method,
      headers: headers,
      ...(method == 'POST' && {
        body: multipart
          ? variables.body
          : new URLSearchParams(variables).toString(),
      }),
    })

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`)
    }

    try {
      const json = await res.json()
      if (json.errors) {
        throw new Error(json.errors)
      }
      return json
    } catch (parseError) {
      console.error('Failed to parse JSON response:', parseError)
      throw new Error('Invalid response from server')
    }
  } catch (error) {
    console.error('API request failed:', error)
    // Return a default response for middleware
    if (query === 'auth/AccountSet') {
      return { banned: false }
    }
    throw error
  }
}
