import { depositModalShow, useAppDispatch } from '@/redux/counterSlice'
import { useControlPanelStore } from '@/store/useControlPanelStore'
import { Image } from '@heroui/react'
import { t } from 'i18next'
import { CardTooltip } from './CardTooltip'
import { formatNumber } from '@gg/shared/utils/helpers'

interface Props {
  balance: number
}
export const GemsCard = ({ balance }: Props) => {
  const dispatch = useAppDispatch()
  const { setIsOpen } = useControlPanelStore()

  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="flex items-center justify-between">
        <p className={`text-purple text-sm font-semibold`}>{t('Gems')}</p>
        <CardTooltip text={t('Gems are used to join elite tournaments')} />
      </div>
      <div
        className={`text-purple border-purple relative w-full cursor-pointer rounded-md border px-4 py-2 text-center`}
        onClick={() => {
          dispatch(depositModalShow())
          setIsOpen(false)
        }}
      >
        <p className={`text-md text-purple font-semibold`}>
          {formatNumber(balance ?? 0)}{' '}
        </p>
        <div className="absolute end-1 top-1/2 -mt-px -translate-y-1/2 self-end">
          <Image
            disableSkeleton
            src="/images/gem/gem.png"
            className="h-6 w-6"
            alt="Gem"
          />
        </div>
      </div>
    </div>
  )
}
