import nodeAPI from '@/lib/nodeapi'
import { useMutation } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

const useCreateTamatemPayment = () => {
  const { data: session } = useSession()

  const mutation = useMutation({
    mutationFn: async ({
      depositAmount,
      paymentMethodName,
    }: {
      depositAmount: number
      paymentMethodName: string
    }) => {
      const res = await nodeAPI(
        'POST',
        session?.secure,
        `finances/create_tamatem_plus_payments`,
        {
          depositAmount,
          paymentMethodName,
        },
      )

      if (res.success) {
        const { url } = res.tamatemResponse
        window.open(url, '_blank')
      }
      return res
    },
  })
  return mutation
}

export default useCreateTamatemPayment
